@each $type in $swatch-types {
    .swatch-#{nth($type, 1)} a{
        background-color: nth($type, 2) !important;
    }
}

.swatch-assorted a {
    background: linear-gradient(217deg,rgba(255,0,0,.8),rgba(255,0,0,0) 70.71%),linear-gradient(127deg,rgba(0,255,0,.8),rgba(0,255,0,0) 70.71%),linear-gradient(336deg,rgba(0,0,255,.8),rgba(0,0,255,0) 70.71%);
}

.swatch-silver a {
    background-color: #d9d9d9;
    background: linear-gradient(45deg, #b5b5b5 20%, #fcfcfc 66%, #e8e8e8 98%);
}

.swatch-gold a {
    background: gold;
    background: linear-gradient(45deg, #d6b56f 42%, #e9e6e1 77%, #fcfcfc 98%);
}

.swatch-unassigned a {
    background-color: #d9d9d9;
    background: linear-gradient(135deg, #e1e1e1 49%, #fff 9%, #e1e1e1 56%);
}

.unselectable.swatch-miscellaneous a, .selectable-partial.swatch-miscellaneous a {
    background: url("../images/interface/icon-color-swatch-misc-unselectable.png") no-repeat scroll left top transparent !important;
}

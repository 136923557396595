.c-top-promo-banner,
.full-width__top-promo-wrap {
    @include make-container-max-widths();
}
.c-top-promo-banner {
    background-color: $very-light-blue;
    color: #46606f;
    font-size: 15px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 0 -#{map-get($grid-gutter-widths, xs)};
    padding: 10px;
    text-align: center;

    @include media-breakpoint-up(lg) {
        margin: 20px auto;
    }

    a {
        &, &:hover {
            color: inherit;
        }
    }

    > :last-child {
        margin-bottom: 0;
    }

}
.full-width__top-promo-wrap {
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
        padding: 0 map-get($grid-gutter-widths, lg);
    }
}

h1,
.h1 {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

h2, .h2 {
    font-weight: 300;
}

h1, h3, h4,
.h1, .h3, .h4 {
    font-weight: 400
}

h2, h3,
.h2, .h3 {
    font-family: $headings-font-family-secondary;
}

h4, h6,
.h4, .h6 {
    font-family: inherit;
}

.mini-header {
    display: block;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    padding: 3px 0;
}


.text-size-xs {
    font-size: $font-size-xs !important;
}
.text-size-sm {
    font-size: $font-size-sm !important;
}
.text-size-base {
    font-size: $font-size-base !important;
}
.text-size-lg {
    font-size: $font-size-lg !important;
}

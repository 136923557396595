html.is-navigation-active {
    @include media-breakpoint-down(md) {
        &, body {
            height: 100%;
            overflow: hidden;
            position: relative;
        }

        .search-suggestion-wrapper {
            visibility: hidden;
        }
    }

}

.menu-toggle {
    background-color: transparent;
    border: none;
    display: flex;
    margin-top: 1px;
    padding: 0;

    &__icon {
        @include size(30px);


        &--burger {
            .is-navigation-active & {
                display: none;
            }
        }

        &--close {
            display: none;

            .is-navigation-active & {
                display: block;
            }

        }

    }


}

#navigation {
    ul, li {
        list-style: none;
    }

    a {
        &, &:hover, &:active, &:visited {
            color: $body-color;
            font-size: inherit;
            font-weight: inherit;
            text-decoration: none;
        }
    }

}

@include media-breakpoint-down(md) {
    #navigation {
        $nav-width: 330px;
        background: $body-bg;
        font-size: 13px;
        font-weight: normal;
        letter-spacing: .9px;
        line-height: normal;
        margin-top: 1px;
        max-height: calc(100vh - 100px);
        max-width: 100vw;
        position: absolute;
            top: 100%;
            left: -$nav-width;
            z-index: $zindex-navbar;
        transition: transform .3s;
        width: $nav-width;

        ul, li {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        ul, li, a {
            display: block;
        }

        .is-navigation-active & {
            overflow: auto;
            transform: translate($nav-width);
        }

        .level-1 {
            > li {
                > a {
                    position: relative;
                    padding-top: 25px;
                    padding-bottom: 25px;

                    &, &:hover {
                        text-transform: uppercase;
                    }
                }
            }
        }

        .level-2,
        .level-3,
        .level-2__row .has-sub-menu > ul,
        .level-2--alt .has-sub-menu > ul {
            max-height: 0;
            overflow: hidden;
            transition: max-height .2s linear;
        }

        .is-active > .level-2,
        .is-active > .level-3,
        .level-2__row .has-sub-menu.is-active > ul,
        .level-2--alt .has-sub-menu.is-active > ul {
            max-height: 300em;
        }

        .has-sub-menu, .pseudo-has-sub-menu {
            > a {
                @include background-svg($svg-accordion-expand);
                background-repeat: no-repeat;
                background-position: calc(100% - 8px) center;
            }

            &.is-active {
                > a {
                    @include background-svg($svg-accordion-contract);
                }
            }

            &.mobile-user-utility {
                border-bottom: 1px solid rgba(#d0d0d0, .5);

                > a {
                    background-image: none;
                    text-transform: capitalize;
                    display: flex;
                    align-items: center;

                    &:hover {
                        text-transform: capitalize;
                        text-decoration: underline;
                    }
                }

                .location,
                .gift-card {
                    &__icon {
                        @include size(16px);
                        margin-right: 7px;
                        height: 24px;
                    }
                }

                .gift-card {
                    &__icon {
                        width: 22px !important;
                        height: 22px !important;
                    }
                }
            }

        }

        .level-1 > li {
            > a {
                &, &:hover {
                    padding: 1rem map-get($grid-gutter-widths, xs);
                }
            }

        }

        .level-2 {
            border-bottom: 1px solid rgba(#d0d0d0, .5);

            > ul {
                margin-top: -5px;
            }

        }

        .level-2,
        .menu-utility-user-mobile {
            a {
                &, &:hover {
                    font-size: 14px;
                    letter-spacing: .5px;
                    padding: 13px map-get($grid-gutter-widths, xs);
                    text-transform: none;
                }
            }

        }

        .level-3 {
            li > a {
                &, &:hover {
                  font-weight: 300;
                  padding: 1rem map-get($grid-gutter-widths, xs);
                  padding-left: map-get($grid-gutter-widths, xs) + 14px;
                }
            }
        }

        .menu-utility-user-mobile {
            background-color: $gray-100;
            border-top: 2px solid rgba(#d0d0d0, .5);
            border-bottom: 1px solid rgba(#d0d0d0, .5);
            padding-bottom: 150px;

            a {
                &, &:hover {
                    border-bottom: 1px solid rgba(#d0d0d0, .5);
                    font-size: 11px;
                    font-weight: normal;
                    letter-spacing: .5px;
                    text-transform: uppercase;
                }
            }

            .user-info,
            .locale-select {
                > a {
                    align-items: center;
                    background-image: none;
                    display: flex;

                    .svg-symbol {
                        @include size(16px);
                        margin-left: 10px;
                        transition: transform .2s;
                    }

                }

                &.has-sub-menu {
                    > a {
                        border-bottom: 0;
                    }
                }

            }

            .user-panel,
            .locale-select__options {
                border-bottom: 1px solid rgba(#d0d0d0, .5);
                max-height: 0;
                overflow: hidden;
                transition: max-height .2s linear;

                a {
                    &, &:hover {
                        text-transform: none;
                        border-bottom: 0;
                    }
                }

            }

            .has-sub-menu {
                .user-panel,
                .locale-select__options {
                    border-bottom: 1px solid rgba(#d0d0d0, .5);
                }
            }

            .is-active {
                .user-panel,
                .locale-select__options {
                    max-height: 200em;
                }

                &.user-info,
                &.locale-select {
                    > a {
                        .svg-symbol {
                            transform: rotate(180deg);
                        }
                    }
                }

            }

        }

    }

}

.gift-certificate-purchase {
    background-color: $gray-100;
    margin-bottom: 1em;
    padding: 1rem;
    .content-asset {
        @media (min-width: 768px) {
            box-sizing: border-box;
            float: left;
            padding-right: 1rem;
            width: 50%;
        }
    }
    h2 {
        color: $black;
        font-family: $font-family-sans-serif;
        font-size: 1.35rem;
        font-weight: $headings-font-weight;
        margin-top: 0.5rem;
    }

    form {
        background-color: $white;
        box-sizing: border-box;
        padding: 0 1.66em 1.66em;
        width: 100%;
        @media (min-width: 768px) {
            float: left;
            width: 50%;
        }
        .cert-amount {
            clear: none;
            float: left;
            margin: 0;
            width: 40%;
        }
        button {
            margin-top: -4px;
            width: 12em; //for IE
            width: max-content;
        }
        .form-row-button {
            clear: none;
            float: left;
            margin: 37px 0 0 1.66em;
            width: 50%;
        }
        .char-count {
            font-size: 0.75rem !important;
            padding-right: 10px;
            text-align: right;
        }
    }
    .cert-amount {
        .form-caption {
            margin: 1.66em 0 0;
            position: absolute;
            width: 40%;
        }
        &.error {
            padding-bottom: 2.92em;
        }
    }
    span.error {
        margin-left: 2%;
    }
}

.check-balance {
    border-bottom: 1px solid $gray-200;
    margin: 0 0 1.66em;
    padding: 0 0 1.66em;
}

.gift-cert-balance {
    float: left;
    width: 40%;
    label {
        display: none;
    }
    input {
        margin-top: 0;
        width: 93%;
    }
    .form-caption {
        margin-left: 0;
    }
}

.gift-cert-balance-submit {
    clear: none;
    float: left;
    margin-right: map-get($grid-gutter-widths, xs);
}

.gift-cert-balance .label {
    display: none;
}

.terms-of-use {
    font-size: 0.813rem;
    margin-bottom: 3rem;
    p {
        strong {
            color: $black;
            font-size: 1.35rem;
            font-family: $font-family-sans-serif;
            font-weight: $font-weight-normal;
        }
    }
}

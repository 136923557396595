$ns: "c-store-status-icon";

.#{$ns} {
    background-repeat: no-repeat;

    &--store-error,
    &--unavailable {
        @include background-svg($svg-no);
    }

    &--limited-stock {
        @include background-svg($svg-limited);
    }

    &--available {
        @include background-svg($svg-yes);
    }

    &--preferred {
        @include background-svg($svg-preferred);
    }

}

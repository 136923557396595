.canada-post-location-result {
    align-items: baseline;
    display: flex;
    margin: map-get($spacers, 3) 0;

    &__radio {
        margin-right: map-get($spacers, 2);
    }

}

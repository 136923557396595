.pt_categorylanding {
    #main {
        .main-row {
            @include media-breakpoint-down(md) {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
    .category-refinement {
        &.c-accordion__item {
            border: none;
        }
        .c-accordion__header {
            background: transparent !important;
            cursor: auto;
            padding-bottom: 0;
        }
        .c-accordion__body {
            padding-bottom: 26px !important;
            padding-top: 10px !important;
        }
    }
}
.category-tile {
    h4 {
        @include media-breakpoint-down(md) {
            font-size: 18px;
            margin-bottom: 0;
        }
    }
}
.category-tile__shop-now {
    color: #000;
    font-size: 12px;
    text-decoration: underline;
}
.bottom-banner-container {
    overflow: hidden;
    .bottom-banner-cell {
        float: left;
        overflow: hidden;
        text-align: center;
    }
    .banner-cell-1 {
        width: 33%;
    }
    .banner-cell-2 {
        width: 34%;
    }
    .banner-cell-3 {
        width: 33%;
    }
}

.cat-featured-bar {
    margin-top: 4px;
}

.infinite-scroll-placeholder{
    text-align: center;

    button{
        border: 1px solid #000;
        text-transform: uppercase;
        background: #fff;
        padding: 15px 10px;
        color: $black;
        font-size: 14px;
        font-weight: 700;
        width: 300px;
        min-width: 150px;
        margin-bottom: 15px;

        &:hover{
            color: $white;
            background: $black;
        }
    }
}
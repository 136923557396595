// Order History - Order Details Styling
.order-history-results {
    list-style: none;
    padding: 0;
    margin: 0;

    &__item {
        display: block;
        margin-bottom: map-get($spacers, 6);
    }
}

.order-history-header {
    background-color: $gray-100;
    border: 1px solid $border-color;
    padding: 10px;
    display: flex;
    justify-content: space-between;

    .label {
        font-weight: 700;
    }

    button {
        float: right;
    }
    .order-date, .order-status, .order-number {
        margin: 2px 0;
        width: 100%;
    }

    .order-details-button {
        display: flex;
        align-items: center;
        .button-fancy-large {
            width: max-content;
        }
    }
}

.order-history-items .order-history-table th, .order-history-items .order-history-table td {
    padding: 5px 2%;
}

.order-history-items {
    margin: 20px 0;
    .order-history-table {
        th, td {
            padding: 5px 10px;
        }
        ul {
            margin: 0;
            padding: 0;
        }
        li {
            list-style-position: inside;
            list-style-type: square;
        }
        .order-shipped-to {
            width: 33%;
        }
        .toggle {
            cursor: pointer;
            display: block;
        }
    }
}
.js .order-history-table .hidden {
    display: none;
}

// Details Styling

.orderdetails {
    font-size: 0.813rem;
    margin: 0;
    width: 100%;

    h2 {
        clear: both;
    }
    .label {
        margin-bottom: .3rem;
        margin-top: .3rem;
    }
    .value {
        font-weight: 700;
    }
    .order-shipment-table {
        background-color: $gray-100;
        border: 1px solid $gray-200;
        border-radius: 5px;
        margin-bottom: map-get($spacers, 4);
        padding: 1em;

        .line-item-details {
            .name {
                font-size: inherit;
            }
        }
    }
    .order-shipment-details {
        display: block;
        flex-wrap: wrap;
        @include media-breakpoint-up(md) {
            display: flex;
        }

        .shipping-status,
        .order-shipment-address,
        .shipping-method {
            width:100%;
            margin-bottom: 20px;

            @include media-breakpoint-up(md) {
                width: 33%;
            }

        }
    }
    .line-items {
        clear: both;
        padding-top: 1rem;
        .line-item {
            border-bottom: 1px solid $light-grey;
            display: block;
            flex-wrap: wrap;
            margin-bottom: 20px;
            width: 100%;
            @include media-breakpoint-up(md) {
                display: flex;
            }

            &:last-of-type {
                border-bottom: none;
                margin-bottom: 0;
            }

            .line-item-details,
            .line-item-quantity,
            .line-item-price {
                padding-bottom: 1.25rem;
                vertical-align: top;
                width:100%;
                @include media-breakpoint-up(md) {
                    width: 33%;
                }
            }
         }
    }
    .order-payment-summary {
        @include media-breakpoint-up(md) {

        }
    }
    .order-information {
        padding-bottom: .5em;
        padding-top: .5em;
        .label {
            font-weight: 400;
            text-transform: none;
        }
        .value {
            font-weight: 700;
        }
        .order-date {
            font-size: 14px;
        }
        .order-number {
            font-size: 1.75rem;

            a {
                color: inherit;
                pointer-events: none;
                text-decoration: none;
            }
        }
    }
    .payment-amount {
        .label {
            font-weight: normal;
            text-transform: none;
        }
    }
    .order-totals-table td {
        &:first-child {
            padding-right: 0.3rem;
            text-align: left;
        }
    }
    .sec-heading {
        background-color: $order-details-heading-bg-color;
        font-weight: 700;
        text-align: center;
        padding: 5px 0;
    }
    .order-summary-details {
        font-size: 14px;

        .label {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 16px;
        }

        .order-payment-summary {
            .item-subtotal,
            .order-total {
                font-weight: 700;
            }
            .order-discount {
                color: $red;
                font-weight: 700;
            }
        }
    }
    .order-shipping-details {
        background-color: $order-details-summary-bg-color;
        font-size: 14px;
        padding: 4px;

        &__heading {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 16px;
        }
    }
    .order-shipping {
        padding: 0 10px;
        @include media-breakpoint-up(lg) {
            padding: 0 20px;
        }
        .order-shipping-details {
            flex: 0 0 45.66667%;
            max-width: 45.66667%;
        }
    }
    .shipment-box {
        background-color: $order-details-shipping-bg-color;
        border: 2px solid $order-details-summary-border-color;
        padding: 4px;

        a {
            color: $black;
            text-decoration: underline;
            font-weight: 700;
        }
    }
    .product-detail__image {
        text-align: center;
        min-width: 100px;
    }
}
.orderdetailsactions.actions {
    a {
        margin-bottom: 1rem;
        text-align: center;
        width: 100%;
        @include media-breakpoint-up(md) {
            width: unset;
        }
    }
}
.product-detail {
    &--container {
        padding: 0 10px;
        @include media-breakpoint-up(lg) {
            padding: 0 20px;
        }
    }

    &__item {
        display: flex;
        gap: 4px 6px;
        margin-bottom: 10px;
    }

    &__label {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 12px;
    }

    &__image {
        text-align: center;

        img {
            max-width: 100px;
            max-height: 100px;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        padding-top: 32px;
        text-transform: capitalize;

        .name {
            font-style: italic;
        }

        .value {
            font-weight: 400;
            text-transform: uppercase;
        }

        .status {
            color: $red;
        }
    }

    &__summary {
        display: flex;
        column-gap: 40px;
        flex-direction: column;
        padding-left: 104px;

        @include media-breakpoint-up(md) {
            flex-direction: row;
            padding-left: 0;
        }
    }

    &__prices {
        display: flex;
        align-items: center;

        @include media-breakpoint-up(md) {
            display: block;
            text-align: center;
        }

        p {
            @include media-breakpoint-down(sm) {
                margin-bottom: 0;
            }
        }

        .qty {
            font-style: italic;
        }

        .product-detail__label {
            @include media-breakpoint-down(sm) {
                width: 60px;
            }
        }

        .product-detail__value {
            @include media-breakpoint-down(sm) {
                width: 100px;
                text-align: center;
            }
        }
    }

    &__value {
        font-size: 12px;
    }
}
.cancellation-redfund {
    hr {
        border-color: $order-details-hr-color;
        border-width: 2px;
    }
}
.pt_order,
.confirmation {
    .actions {
        a {
            display: inline-block;
            margin-right: map-get($spacers, 4);
        }
    }
}
.confirmation-message {
    flex: 0 0 100%;
    padding: 1rem 10px;

    @include media-breakpoint-up(lg) {
        padding-left: map-get($grid-gutter-widths, xs);
        padding-right: map-get($grid-gutter-widths, xs);
    }
}
.confirmation {
    margin: 0;

    @include media-breakpoint-up(lg) {
        margin: 0 -#{map-get($grid-gutter-widths, xs)};
    }

    &.create-account {
        display: flex;
        flex-wrap: wrap;

        .login-create-account,
        .order-confirmation-details {
            display: inline-block;
            vertical-align: top;
        }
        .login-create-account {
            flex: 0 0 100%;
            margin: 0 auto map-get($spacers, 4);

            @include media-breakpoint-up(lg) {
                flex-basis: 28%;
                margin-bottom: 0;
                max-width: 28%;
            }
        }
        .order-confirmation-details {
            flex: 0 0 100%;
            margin-bottom: 20px;

            @include media-breakpoint-up(lg) {
                flex-basis: 72%;
                max-width: 72%;
            }
        }
        .login-box-content {
            background-color: $gray-100;
            border: 1px solid $gray-200;
            border-radius: 5px;
            padding: 1em;
            p {
                margin-top: 0;
            }
            .form-row {
                margin: 0 0 10px;
            }
        }
    }
    .login-create-account,
    .order-confirmation-details,
    .actions {
        padding: 0 10px;

        @include media-breakpoint-up(lg) {
            padding: 0 map-get($grid-gutter-widths, xs);
        }
    }
    .c-form-row.countryselect {
        display: none;
    }
}

.order-summary {
    @include media-breakpoint-up(md) {
        display: flex;
    }
    display: block;
    @include media-breakpoint-up(md) {
        .order-billing,
        .order-payment-instruments,
        .order-payment-summary {
            width: 33%;
        }
    }

    .order-billing,
    .order-payment-instruments,
    .order-payment-summary {
        border-bottom: 1px solid $border-color;
        width: 100%;
        .order-total {
            & td:first-child {
                font-weight: 400;
            }
        }
    }
}

.order-shipments {
    h1 {
        font-size: 1.75rem;
    }
}

.order-confirmation-item-image {
    width: 25%;
}
$half-xs-gutter: map-get($grid-gutter-widths, xs) * .5;

.ui-dialog #listings-nav {
    float: right;
    margin-top: 2%;
    a {
        cursor: pointer;
        display: inline-block;
        margin: 0 5px;
        &.active {
            font-weight: bold;
        }
    }
}

.pdp-main .product-add-to-cart {
    .online-stock span {
        float: left;
    }
}

// Store inventory

.availability-web {
    padding-bottom: 1rem;
    label {
        float: none;
    }
    p {
        margin: 0;
    }
    .availability-novariation {
        font-style: italic;
    }
}
.availability-storepickup {
    .availability-instore,
    .availability-web {
        padding: 12px 0;
    }
    .availability-web {
        float: none;
    }

}

.availability-instore {
    font-size: 1rem;
    padding-top: 0;
    width: 100%;

    &__label {
        font-family: inherit;
        font-size: 1rem;
    }

}

.in-stock-msg,
.is-in-stock,
[data-status="store-in-stock"] {
    color: $cerulean;
    font-size: 0.813rem;
}

.availability-novariation,
.not-available-msg,
.in-stock-date-msg,
.not-available,
.out-of-stock,
.store-error {
    color: $danger;
}

.on-order,
.preorder-msg,
.backorder-msg {
    color: $warning;
    font-weight: 700;
}

#user-zip {
    display: block;
    margin: 0;
}

.store-list-container {
    margin: 0 auto;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    padding-bottom: 20px;
}
#preferred-store-panel {
    margin-bottom: 0;
}
.find-in-store-modal {

    .ui-dialog-content {
        max-height: calc(100% - 76px) !important;
    }

    &.ui-dialog {
        .ui-widget-header {
            height: auto;
        }
        .ui-dialog-title {
            font-size: 20px;
            font-family: inherit;
            text-transform: none;
        }
        .ui-dialog-buttonpane {
            padding: 0;
            position: absolute;
            bottom: 7px;
            background: $white;
            width: 100%;
        }
    }
    .store-address {
        margin-bottom: map-get($spacers, 4);
    }
    .button-fancy-large {
        margin-right: map-get($grid-gutter-widths, xs);

        &.select-store-button {
            margin-right: 0;
        }
    }
    .no-results {
        padding: 0;
    }
    .ui-dialog-buttonset {
        button {
            margin-bottom: map-get($spacers, 4);

            @include media-breakpoint-up(sm) {
                margin-bottom: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.store-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none outside none;
    margin: -#{$half-xs-gutter} 0;
    padding: 0;

    @include media-breakpoint-up(sm) {
        margin-left: -#{$half-xs-gutter};
        margin-right: -#{$half-xs-gutter};
    }
}

.store-tile {
    border: 1px solid #a6a6a6;
    box-sizing: border-box;
    flex: 0 0 100%;
    font-weight: $font-weight-light;
    list-style: none outside none;
    margin: $half-xs-gutter 0;
    padding: map-get($grid-gutter-widths, xs);
    text-align: center;

    @include media-breakpoint-up(sm) {
        flex-basis: calc(50% - #{map-get($grid-gutter-widths, xs)});
        margin-left: $half-xs-gutter;
        margin-right: $half-xs-gutter;
        max-width: calc(50% - #{map-get($grid-gutter-widths, xs)});
    }
    @include media-breakpoint-up(lg) {
        flex-basis: calc(33.3% - #{map-get($grid-gutter-widths, xs)});
        max-width: calc(33.3% - #{map-get($grid-gutter-widths, xs)});
    }

    &.selected {
        background-color: $white-three;
        border-color: #000;

        .button-fancy-large,
        .button-fancy-large:active {
            background-color: $blue;
            border-color: $blue;
            color: #fff;
        }
    }
    .button-fancy-large {
        padding-left: 22px;
        padding-right: 22px;

        @include media-breakpoint-up(lg) {
            min-width: 150px;
            padding-left: 18px;
            padding-right: 18px;
        }
    }
    .store-status {
        display: block;
        font-size: 16px;
        margin: 16px 0;
    }
}

.store-list-pdp-container {
    margin: 1em 0;

    .store-list-pdp {
        list-style: none;
        margin-top: 0.5em;
    }

    .stores-toggle {
        padding: 0;
        font-size: inherit;
    }

    .store-list-item {
        display: none;
        font-weight: normal;
        padding: 0.5em  0;

        &:last-of-type {
            border: none;
        }

        &.selected,
        &.visible {
            display: flex;
            justify-content: space-between;
        }

        &.selected {
            font-weight: bold;
        }

        .store-status {
            @include media-breakpoint-up(sm) {
                margin-left: .5em;
            }
        }

    }

}

// Cart inventory
.selected-store-address {
    margin-top: 0.5em;
}
.selected-store-availability {
    margin: 0.3em 0 0.5em;
}

.multishipaddressestable {
    margin: 9px 0px 20px 0px;
    width: 706px;
}

.multi-ship-shipments-table {
    margin: 0 0 20px;
    width: 706px;
    .section-header {
        font-size: 10px;
        font-weight: bold;
        padding: 9px 6px 11px;
    }
    .shipping-address .details {
        padding: 0 10px 0 22px;
    }
    .gift-options {
        label {
            float: left;
            padding: 0;
        }
        input {
            margin: 0 10px;
        }
    }
}

.multi-shipping-addresses {
    margin-bottom: 20px !important;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    .shipping-address {
        text-align: right;
        select {
            margin: 4px 0 0;
            width: 260px;
        }
    }
}

.header-product-shipping {
    width: 276px;
}

.header-qty-shipping {
    width: 45px;
}

.header-location-shipping {
    text-indent: 70px;
    width: 307px;
}

.header-shipment-index {
    width: 409px;
}

.header-shipment-qty {
    width: 27px;
}

.header-shipment-details {
    text-indent: 22px;
}

.multi-shipping-methods {
    position: absolute;
    top: 30px;
    .form-row {
        clear: none;
        float: left;
        display: none;
    }
    select {
        width: 250px;
        margin: -5px 0 0 15px;
    }
}

.multi-shipping-option {
    border-bottom: 2px dotted $gray-500;
    left: 0;
    padding: 0 0 12px;
    position: absolute;
    text-indent: 10px;
    top: 48px;
    width: 95%;
}

.multi-shipping-addresses .shipping-qty,
.multi-ship-shipments-table .shipping-qty {
    background: none repeat scroll 0 0 $gray-100;
}

.multiship .label {
    font-weight: bold;
}

.hide {
    display: none;
}

.loading {
    background: url("../images/loading-progress-98x22.gif") no-repeat scroll left center transparent;
}

.instore-shipping-option {
    width: 100%;
}

.pt_checkout {
    .instore-shipment-header {
        width: 100%;
    }
    .instore-shipment-method {
        width: 100%;

        span {
            font-weight: bold;
        }
    }
    .instore-shipment-address {
        margin-bottom: map-get($spacers, 4);
        width: 40%;
    }
    .instore-shipment-message {
        clear: both;
    }
}


.ui-widget-overlay {
    background: url("../images/interface/ui-overlay-shadow.png") repeat scroll 50% 50% $gray-800;
    bottom: 0;
    opacity: 0.4;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: $zindex-modal;
}
.ui-widget-overlay.ui-front, .ui-dialog.ui-front { // increased specificity to override style added by paypal on cart page
    z-index: $zindex-modal;
}
.ui-dialog {
    background-color: $white;
    height: 90vh !important;
    left: 50% !important;
    max-height: 600px;
    max-width: 800px;
    overflow: hidden;
    position: fixed !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
    width: 90vw !important;
    z-index: $zindex-modal;

    @include media-breakpoint-up(lg) {
        height: 68vh !important;
        width: 68vw !important;
    }

    // modifier to allow dialog to better fit it's content
    &--relax {
        height: auto !important;
    }

    .ui-widget-header {
        border: 0 none;
        border-radius: 0 0 0 0;
        height: 23px;
        text-transform: uppercase;
    }
    .ui-dialog-title {
        color: $gray-800;
        font-weight: bold;
    }
    .ui-icon-closethick {
        background-image: url("../images/interface/icon-ui-close.png");
        background-position: left top;
        height: 32px;
        width: 32px;
        left: 0;
        top: 0;
        margin-left: 0;
        margin-top: 0;
    }
    .ui-dialog-titlebar-close {
        @include background-svg($svg-modal-close);

        background-color: transparent;
        border: 0 none;
        border-radius: 0 0 0 0;
        cursor: pointer;
        height: 32px;
        margin: 0;
        padding: 0;
        position: absolute;
        right: 9px;
        top: 9px;
        width: 32px;

        span {
            margin: 0;
            padding: 0;
        }
    }
    .ui-widget-content {
        padding: 20px;
    }
}

.ui-dialog-titlebar {
    padding: 20px;
    position: relative;
}
.no-close {
    .ui-dialog-titlebar-close {
        display: none;
    }
}

.ui-dialog-content {
    max-height: calc(100% - 40px) !important;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100% !important;

    .pdp-main .pdp-main-row {
        margin: 0;
    }
}

.ui-accordion .ui-accordion-content {
    padding: 0;
}

.ui-tabs {
    .ui-tabs-nav {
        height: 2.45em;
        padding: 0;
        li {
            border-color: $white;
            border-style: solid;
            border-width: 0 0 3px 0;
            &.ui-state-active {
                border-bottom-color: $body-color;
                a {
                    color: $black;
                    font-weight: 700;
                }
            }
            a {
                color: $gray-500;
            }
        }
    }
    .ui-tabs-panel {
        background-color: $white;
        border: 1px solid $white;
        line-height: 1.5em;
        margin: 0;
        padding: 20px 10px;
        h3 {
            @media screen and (min-width: 768px) {
            display: none;
            }
        }
    }
}

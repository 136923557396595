@import "nav";
@import "nav-desktop";
@import "menu_utility";

.header-banner-container {
    @include make-container();
    @include make-container-max-widths();
    margin: auto;

    @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: end;
    }

}

.header-banner {
    background-color: $white;
    overflow: hidden;
    position: relative;
    max-height: 75px;
    transition: max-height .25s;
    z-index: $zindex-header-banner;

    .is-navigation-active & {
        display: none;
    }

    @include media-breakpoint-up(lg) {
        max-height: none;
        overflow: visible;
    }

    &__banner,
    &__util {
        padding: 8px 0 7px;
    }

    &__banner {
        font-size: 11px;
        font-weight: normal;
        letter-spacing: .6px;
        line-height: normal;
        text-align: center;

        @include media-breakpoint-up(lg) {
            font-size: 12px;
            text-align: left;
        }

    }

    &__util {
        display: none;
        text-align: right;

        @include media-breakpoint-up(lg) {
            display: block;
        }

    }

}

#header {
    background-color: $body-bg;
    border-bottom: 1px solid $border-color;
    position: relative;
    z-index: $zindex-header - 2;

    @include media-breakpoint-down(md) {
        border-bottom: 1px solid $border-color;
    }

    .has-sticky-header & {
        -webkit-transform: translate3d(0, 0, 0); // forces hardware acceleration. Helps stop flickering of fixed positioned elements.
        position: fixed;
            top: 0;
            right: 0;
            left: 0;
            z-index: $zindex-header;
        width: 100%;
    }

    .header-container {
        @include make-container();
        @include make-container-max-widths();
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: auto;
        padding-top: 9px;
        padding-bottom: 14px;
        position: relative;

        @include media-breakpoint-up(lg) {
            align-items: center;
            padding-top: 0;
            padding-bottom: 0;
        }

        @include media-breakpoint-down(md) {
            padding-top: 15px;
            padding-bottom: 15px;

            &.padding-navigation {
                padding: 0;
            }
        }

        &__left {
            margin-left: -5px;

            @include media-breakpoint-up(lg) {
                margin-left: 0;
            }

        }

        &__right {
            margin-right: -5px;

            @include media-breakpoint-up(lg) {
                margin-right: 0;
                margin-left: 0;
            }

            @include media-breakpoint-down(md) {
                margin-left: 5px;
            }

            .user-info {
                position: relative;

                .user-panel {
                    background-color: $body-bg;
                    box-shadow: 0 20px 30px -5px rgba(116, 116, 116, 0.4);
                    display: none;
                    font-size: 12px;
                    font-weight: normal;
                    line-height: 2.5;
                    letter-spacing: .5px;
                    margin-left: -1rem;
                    padding: .8rem 0;
                    position: absolute;
                    top: 100%;
                    z-index: $zindex-header-dropdown;
                    text-align: left;
                    text-transform: none;
                    width: 162px;

                    &__link {
                        display: block;
                        padding: 0 1rem;
                        color: $black;
                    }

                    @include media-breakpoint-down(md) {
                        display: none !important;
                    }
                }

                &.active,
                &:hover {
                    .user-panel {
                        display: block;
                    }
                }
            }

        }

    }

    .user-info {
        display: flex;
        align-items: center;
        margin-top: -3px;

        .user-account,
        .menu-utility-user__item {
            .user-toggle {
                &__icon {
                    @include size(30px);

                    &.svg-symbol {
                        height: 28px !important;
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                margin-right: -3px;
                padding: 10px 0 10px !important;
            }
        }

    }

    .stores {
        .menu-utility-user__item {
            @include media-breakpoint-up(lg) {
                margin-right: -3px;
                padding: 10px 0 10px !important;
            }
        }

        .location {
            &__icon {
                @include size(26px);

                &.svg-symbol {
                    height: 24px !important;
                }
            }
        }
    }

    .user-toggle__icon,
    .location__icon {
        margin: 0 5px;
    }

    .search-toggle {
        color: $body-color;
        display: flex;
        font-size: 13px;
        font-weight: normal;
        letter-spacing: .5px;
        margin-left: 8px;

        &__icon {
            @include size(32px);
        }

        &--desktop {
            display: none;
        }

        @include media-breakpoint-up(lg) {
            display: none;

            &--desktop {
                border-bottom: 3px solid transparent;
                align-items: center;
                margin-left: 0;
                padding: 22px 0;

                .is-search-active & {
                    border-color: $primary;
                }

            }

        }
    }

}

@include media-breakpoint-down(lg) {
    html[lang="fr"] {
        .search-toggle__label {
            @include sr-only();
        }
    }
}

.primary-logo {
    line-height: 0;
    margin-left: 1px;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 137px; // width of image

    a {
        display: inline-block;
        max-width: inherit;
    }

    @include media-breakpoint-up(lg) {
        max-width: 281px;
        width: 100%;
        text-align: left;

        > a {
            max-width: 192px;
        }

        html[lang="fr"] & {
            min-width: 192px;
        }

    }

    @include media-breakpoint-down(md) {
        margin-right: 10px;
        margin-left: 12px;
    }

    @include media-breakpoint-down(sm) {
        img {
            max-width: 80px;
        }
    }
}

.header-search {
    background-color: $white;
    padding: 0;
    top: 100%;
    right: 0;
    width: 100%;
    z-index: 3;
    border: 1px solid $light-grey;
    border-radius: 3px;
    position: relative;
    margin-right: 0px;
    margin-top: 0px;

    .header-search__submit__icon {
        margin-left: 5px;
    }

    .search-suggestion-wrapper.full .product-suggestions {
        border-top: none !important;
    }

    @include media-breakpoint-down(md) {
        max-width: 50%;
        margin-left: auto;
        margin-right: auto;

        .header-search .header-search__input {
            padding: 10px 0 10px 5px;
        }
    }

    @include media-breakpoint-down(sm) {
        position: initial;
        width: 100%;
        border-radius: 4px;

        .header-search__input,
        .header-search__input::placeholder {
            font-size: 12px !important;
        }

        .search-suggestion-wrapper {
            position: absolute;
            left: 0;
            right: 0;
            margin-top: 17px;
        }
    }

    @include media-breakpoint-up(lg) {
        width: 500px;

        .search-suggestion-wrapper {
            margin-left: 0 !important;
            max-width: 500px;
            width: 100% !important;
            border-top: 1px solid #ddd9d7 !important;
        }
    }

    #{&}__container {
        display: flex;
        margin-bottom: 0;
    }

    #{&}__input {
        border: 0;
        font-size: 16px; // mist be 16px to prevent iOS zoom
        font-weight: normal;
        letter-spacing: normal;
        line-height: normal;
        max-width: none;
        padding: 15px 0 14px 5px;
        width: 100%;

        &:focus {
            outline: 0;
        }

        @include media-breakpoint-up(lg) {
            font-size: 14px;
        }

        @include media-breakpoint-down(md) {
            padding: 10px 0 10px 5px;
        }

        @include media-breakpoint-down(sm) {
            padding: 8px 0 7px 8px;
        }

    }

    #{&}__submit {
        background: none;
        border: none;
        display: flex;
        justify-content: center;
        padding: 0;
        align-items: center;

        &__icon {
            @include size(32px);
        }

    }


    .is-search-active & {
        display: block;
    }

}



.user-account-info,
.user-info,
.stores {
    .user-toggle,
    .user-account,
    .menu-utility-user__item {
        align-items: center;
        color: $body-color;
        display: flex;
        font-size: 13px;
        font-weight: normal;
        letter-spacing: .5px;

        @include media-breakpoint-up(lg) {
            margin-right: -3px;
            padding: 23px 0 22px;
        }
    }
}

.blue-bar-header {
    .owl-carousel {
        margin: 0 !important;
    }
}

.pt_checkout,
.pt_order-confirmation {
    .blue-bar-header {
        display: none;
    }
}

.checkoutlogin {
    margin: 4em auto;

    @include media-breakpoint-up(lg) {
        width: 80%;
    }

    .col-1 {
        @media screen and (min-width: 768px) {
            border-right: 1px solid $gray-200;
        }
    }
    .formbuttonrow,
    .form-row-button {
        margin-bottom: 10px;
    }
    .col-6 {
        flex-basis: 100%;
        margin-bottom: 20px;
        max-width: 100%;

        @include media-breakpoint-up(lg) {
            flex-basis: 50%;
            max-width: 50%;
        }
    }
}

.login-box {
    h2 {
        @extend .h3;
        font-family: inherit;
    }
    h3 {
        margin: 0;
    }

    p {
        @include media-breakpoint-up(md) {
            max-width: 427px;
        }
        max-width: 100%;
        margin: 1em 0 10px;
    }

    .error {
        margin-left: 0;
    }

    form {
        margin-top: 1.4rem;
    }

    .login-rememberme {
        display: inline-block;
        margin-left: 1.55rem;
    }

    .c-form-row__label {
        color: $warm-grey;
    }

    a {
        display: inline-block;
        font-size: 0.813rem;
        margin-top: 1rem;
        text-decoration: underline;
    }

    .form-row-button {
        margin-top: 1.55rem
    }

    &.login-order-track {
        margin: 2.5rem 0;
    }

    .input-text {
        @include media-breakpoint-up(md) {
            max-width: 427px;
        }
        max-width: 100%;
    }

    &.login-create-account {
        p {
            margin-bottom: 0;
        }

        form {
            margin-top: 0;
        }

        .content-asset {
            margin: 2.5rem 0;
            h6,
            .h6 {
                margin: 0.8rem 0 0 0;
            }

            p {
                margin-top: 0;
            }
        }
    }

    .createbenefits {
        .content-asset {
            margin: 0.63rem 0 0 0;
        }
    }

    li {
        font-size: 0.813rem;
    }

    &.login-general {
        .form-row-button {
            margin-top: 0;
        }

        p {
            @include media-breakpoint-up(md) {
                max-width: 90%;
            }
        }
    }
}

.login-rememberme {
    margin-left: 12px;
    white-space: nowrap;

    @include media-breakpoint-up(lg) {
        margin-left: .5em;
    }
}

.login-oauth p:first-child {
    font-size: 22px;
    text-align: center;
    margin: 0;
    text-transform: uppercase;
}

#OAuthProvider {
    display: block;
    margin-bottom: 1em;
    width: 80%;
}

.one-column-header {
    text-align: center;
}

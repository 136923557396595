button,
.button {
    cursor: pointer;

    //Re-declare btn-outline-primary with settings to not change on hover, per the comps
    &.btn-outline-primary {
        @include button-outline-variant($primary, $primary, transparent);
    }
    &.btn-outline-dark {
        @include button-outline-variant($body-color, $body-color, transparent);
    }
}
.button {
    &-fancy-large {
        @extend .btn-secondary;

        border-style: solid;
        font-size: .75rem;
        font-weight: $font-weight-bold;
        letter-spacing: 1.5px;
        min-width: 60px;
        padding: 13px 15px;
        text-transform: uppercase;

        //disabled class to mimic disabled styling without adding the disabled attribute
        &.disabled {
            background-color: gray;
            border-color:gray
        };

        &:disabled {
            cursor: auto;
            opacity: .35;
        }
    }

    &-text {
        @extend .btn-link;
    }
}


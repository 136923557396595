.s-dl {

    dl {
        display: table;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: map-get($spacers, 3) 0;
        width: 100%;

        > div {
            display: table-row;

            > {
                dt, dd {
                    clear: none;
                    display: table-cell;
                    float: none;
                    padding: .25em 0;
                }
            }

        }

        dt,
        dd {
            margin: .25em 0;
        }

        dt {
            font-weight: 400;
            padding-right: .5em;

            &::after {
                content: ':';
            }

        }

        dd {
            padding: 0;
            font-weight: 300;
            text-align: right;
        }

    }

}

$ns: c-image-grid;

.#{$ns} {
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
    }
    &__item {
        @include make-col(4);

        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: map-get($spacers, 5);
        padding: 5px 10px;

        @include media-breakpoint-up(lg) {
            @include make-col(2);

            padding: 0 15px;
        }
    }
    &__link {
        max-width: 135px;
    }
}

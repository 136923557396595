#wrapper {
    border: 0;
    left: 0;
    position: relative;
    transition: left 0.2s ease-in;

    &.menu-active {
        left: 50%;
    }

    @include media-breakpoint-up(lg) {
        &.menu-active {
            left: auto;
        }
    }
}

#main {
    @include make-container();
    @include make-container-max-widths();
    margin: auto;

    &.full-width {
        @include media-breakpoint-up(lg) {
            margin-top: 20px;
        }
    }

    .main-row {
        @include make-row();

        &--reverse {
            @include media-breakpoint-up(lg) {
                flex-direction: row-reverse;
            }
        }
        &--mobile-column-reverse {
            flex-direction: column-reverse;

            @include media-breakpoint-up(lg) {
                flex-direction: row;
            }
        }

    }

    .pt_product-details & {
        margin-right: 0;
        margin-left: 0;
        max-width: none;
        padding: 0;

        .breadcrumb,
        .pdp-main-container {
            // padding-left: 5px;
            // padding-right: 5px;

            @include media-breakpoint-up(sm) {
                @include make-container();
                @include make-container-max-widths();
            }
        }
        .pdp-reviews-container { // this style is added here instead of reviews-2 because this file loads sooner on the page, allowing the placeholder height to make the layout shift less noticeable
            min-height: 26px;
        }
        .desktop-only {
            display: block;
    
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        .mobile-only {
            display: none;
            @include media-breakpoint-down(sm) {
                display: block;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        h1.mobile-only {
            @include media-breakpoint-down(sm) {
                width: 100%;
                margin-bottom: 15px;
            }
        }
        .breadcrumb {
            padding-left: 15px;
            padding-right: 15px;

            @include media-breakpoint-down(xs) {
                line-height: 1.5;
            }
            @include media-breakpoint-up(sm) {
                padding-left: 48px;
                padding-right: 48px;
            }
            @include media-breakpoint-up(md) {
                padding-left: map-get($grid-gutter-widths, lg);
                padding-right: map-get($grid-gutter-widths, lg);
            }
        }
    }
    
    #targeted-popup{
        display: none;
    }
}

#primary {
    max-width: 100%;
    width: 100%;
    .pt_product-details & {
        padding-left: 0;
        padding-right: 0;
    }

    @include make-col-ready();

    @include media-breakpoint-up(lg) {
        @include make-col(9);

        .full-width & {
            @include make-col(12);
        }
    }

}

#secondary {
    padding: 0 10px;
    width: 100%;
    @include make-col-ready();

    @include media-breakpoint-up(lg) {
        @include make-col(3);

        min-width: 0;

        nav {
            a {
                display: block;
            }
        }
        .primary-focus & {
            background: none;
            border: none;
        }
    }

    .primary-focus & {
        margin-left: auto;
        margin-right: auto;
    }

    // Remove Refinements for responsive design
    .pt_product-search-result &,
    .pt_content-search-result &,
    .pt_order & {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }

}
.pt_customer-service.s-contact-us,
.pt_product-search-noresult {
    .main-row {
        flex-direction: column-reverse;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }
    }
}
.pt_customer-service {
    &.s-contact-us {
        .confirmation-message {
            padding-left: map-get($grid-gutter-widths, xs);
            padding-right: map-get($grid-gutter-widths, xs);
        }
    }
}

.sl-icon {
    display: inline-block;

    &:focus {
        outline: none;
    }

    &--2x {
        font-size: 2em;
    }

    &.sl-arrow-icon {
        height: 0.7em;
        width: 0.7em;
        border-style: solid;
        border-color: currentColor;
        border-width: 0 0.1em 0.1em 0;

        &--thin {
            border-width: 0 0.05em 0.05em 0;
        }

        &--right {
            transform: rotate(-45deg);
        }

        &--left {
            transform: rotate(135deg);
        }

        &--up {
            transform: rotate(-135deg);
        }

        &--down {
            transform: rotate(45deg);
        }
    }
}

$ns: "c-qty";

.#{$ns} {
    align-items: center;
    display: flex;
    margin: 0;

    &__input {
        appearance: none;
        -moz-appearance: textfield;
        font-size: 15px;
        min-width: 2.8em;
        margin-right: 8px;
        margin-left: 8px;
        max-width: 46px;
        padding: 10px 10px 12px 13px;
        width: auto;
        text-align: center;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            display: none;
        }

    }

    &__btn {
        background-color: $gray-100;
        border: none;
        color: $body-color;
        height: 32px;
        padding: 0;
        width: 32px;

        &__icon {
            display: block;
            height: 16px;
            margin: auto;
            pointer-events: none;
            width: 16px;
        }

    }

}

$pdp-general-padding: map-get($grid-gutter-widths, xs);

.product-sales-price,
.price-sales {
    &.has-standard-price {
        color: $cherry-red;
    }
}
.active-promo-names {
    color: $cherry-red;
}
.pt_product-details {
    .primary-content {
        width: 100%;
    }
}

.pdp-main {

    h1 {
        margin-left: 0;
        margin-right: 0;
        text-align: left;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    label {
        padding: 0;
        text-align: left;
        width: auto;
    }

    .pdp-main-row {
        @include make-row();

        @include media-breakpoint-down(sm) {
            margin-left: 0;
            margin-right: 0;
        }

        @include media-breakpoint-up(md) {
            .product-set .product-thumbnails{

                .slick-prev{
                    position: relative;
                    left: 50px;
                    transform: rotate(90deg);
                }
                .slick-next{
                    position: relative;
                    left: 50px;
                    transform: rotate(-90deg);
                }
            }
        }
    }

    .product-col-1,
    .product-col-2,
    .product-col-inner {
        @include make-col-ready();
    }
    .product-col-1 {
        @include media-breakpoint-up(md) {
            display: flex;
            flex-flow: column;
        }
        @include media-breakpoint-down(sm) {
           padding: 0;
           flex-wrap: nowrap;
        }
    }
    .product-col-1,
    .product-col-2 {
        @include media-breakpoint-up(md) {
            @include make-col(6);

            padding-bottom: $pdp-general-padding;
        }
    }
    .product-col-2 {
        padding-bottom: $pdp-general-padding;
        padding-left: 15px;
        padding-right: 15px;

        &.product-set{
            @include media-breakpoint-up(md) {
                max-height: 73vh;
                overflow-y: scroll;
            }
        }
    }
    .product-col-inner {
        @include media-breakpoint-down(sm) {
            padding: 0;
        }
        @include media-breakpoint-up(lg) {
            @include make-col(6);
        }
    }

    .product-image-container {
        display: flex;
		flex-wrap: wrap;

        @include media-breakpoint-down(sm) {
            flex-wrap: nowrap;
            padding-bottom: 15px;
        }
    }

    .product-col-1.product-set {
        display: flex;
		flex-wrap: wrap;
		height: 100%;
		min-height: 50vw;

        @include media-breakpoint-down(sm) {
            flex-wrap: nowrap;
            min-height: calc(100vw + 40px);
        }
        @include media-breakpoint-up(lg) {
            min-height: unset;
        }
    }

    .product-col-inner {
        display: flex;
        flex-wrap: wrap;

        > * {
            &:not(.sr-only) {
                width: 100%;
            }
        }
    }
    .product-col-inner--1 {
        align-content: flex-start;
    }
    .product-col-inner--2 {
        align-content: flex-end;
    }
    .product-brand,
    .product-name {
        display: block;
        font-family: $font-family-primary;
        font-size: 24px;
        letter-spacing: -.3px;

        @include media-breakpoint-up(sm) {
        font-size: $pdp-header-font-size;
        }
    }
    .product-brand {
        font-weight: 600;
        margin-bottom: 6px;

        @include media-breakpoint-up(sm) {
        margin-bottom: 0;
        }
    }
    .product-brand-link {
        color: $black;
    }
    .product-name {
        font-weight: $font-weight-light;
        line-height: normal;

        a {
            color: inherit;
        }
    }
    .product-number {
        color: $gray-400;
        display: none;
        font-size: .65rem;
        margin-bottom: .5rem;
    }
    .product-price {
        font-size: 1.5rem;
        line-height: normal;
        margin-bottom: 15px;
        .price-standard {
            color: $gray-500;
            font-weight: 100;
            text-decoration: line-through;
            display: inline-block;
        }
        .price-tiered {
            color: $red;
            font-size: .6em;
            line-height: 1.5em;
        }
    }
    .price-standard{
        margin-right: 10px;
    }
    .price-sales.final-flag {
        font-size: 1rem;
    }
    .percent-savings {
        font-size: 12px;
    }
    .active-promo-names {
        font-size: 14px;
    }
    .product-review {
        margin-bottom: 13px;
        min-height: 18px;
    }
    .product-short-description {
        font-weight: 300;
        margin-bottom: $pdp-general-padding;

        @include media-breakpoint-up(lg) {
            font-size: 16px;
        }
    }
    .product-long-description {
        @include media-breakpoint-up(lg) {
            margin-bottom: 38px;
        }
    }
    .country-availability {
        font-size: 16px;
        font-weight: normal;
        letter-spacing: .5px;
        // margin-bottom: 14px;

        .attribute {
            padding: .2em 0;
        }
        .label {
            padding: 0;
        }
    }
    .product-primary-image {
        display: flex;
        overflow: hidden;
        text-align: center;
        flex-basis: 100%;
        @include media-breakpoint-up(md) {
            flex-basis: 66%;
            flex-grow: 2;
            width: 66%;
        }
        .slick-track {
            display: flex;
        }
    }
    .product-primary-image-inner {
        width: 100%;
        padding-bottom: 20px;
        @include media-breakpoint-up(lg) {
            max-height: 500px;
            padding-bottom: 0;
        }

        &:not(.slick-initialized) {
            .product-image {
                display: none;

                &:first-child {
                    display: block;
                }
            }
        }

        .slick-slide {
            align-self: center;
        }
    }
    .slick-dots {
        height: 30px;
        margin-top: 20px;
    }
    .pdp-below-productimages-wrapper .slick-dots {
        height: 0;
    }
    .pdp-below-productimages-wrapper, .pdp-below-productdetails-wrapper {
		display: none;
        flex-basis: 100%;
        max-width: 100%;
        width: 100%;
        .c-product-tile-carousel__header {
            margin-bottom: 20px;
            h2 {
                text-align: left;
                font-size: 18px;
                text-indent: 1em;
                margin-bottom: 0;
            }
        }
        .product-tile {
            .product-price {
                margin-bottom: 0;
            }
            .reviews-compare-wrap {
                margin: 0;
            }
        }

        &.mobile-only {
            display: none;
            @include media-breakpoint-down(md) {
                display: block;
                margin-bottom: 45px;
            }

            .c-product-tile-carousel {
                margin: 0;

                .product-tile {
                    padding-left: 10px;
                }
            }

            .c-product-tile-carousel--4up {
                .c-product-tile-carousel__header {
                    padding-left: 10px;
                }
                .product-price {
                    .percent-savings {
                        font-size: 12px;
                        display: inline-block;
                    }
                }

                .active-promo-names {
                    font-size: 12px;
                    display: inline-block;
                }
            }
        }

        &.desktop-only {
            display: block;

            .slick-dots {
                bottom: 0;
            }

            @include media-breakpoint-down(md) {
                display: none;
            }

						.c-product-tile-carousel {
							margin: 0;
							width: 100%;
						}

						.c-product-tile-carousel--4up {
							.product-price {
								font-size: 12px;
							}
							.percent-savings {
								font-size: 12px;
								display: inline-block;
							}
							.color-count {
								display: block !important;
								font-size: 12px;
							}
							.product-swatches {
								display: none;
							}
							.active-promo-names {
								font-size: 12px;
								display: inline-block;
							}
						}
        }
    }
    .tfc-product-suppressed {
        display: none !important;
    }
    .product-info {
        background: $tertiary;
        font-weight: $font-weight-light;
        width: auto;
        // Height for PDP Tabs
        @include media-breakpoint-up(lg) {
            padding-left: 0;
            padding-right: 0;
            padding-top: 1em;
        }
        ul {
            font-size: .8rem;
            @include media-breakpoint-up(lg) {
                padding-bottom: 1em;
                padding-top: 1em;
            }
        }
        .c-accordion__item {
            border-bottom-width: 2px;

            &:first-of-type {
                border-top: none;
            }
            &:last-of-type {
                border-bottom: none;
            }
        }
        .table {
            margin-bottom: 40px;
        }
        .tab-content {
            padding-left: 0;
            padding-right: 0;

            &__2-col-wrap {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
            &__col {
                flex: 0 0 100%;
                margin-bottom: map-get($grid-gutter-widths, xs);

                @include media-breakpoint-up(lg) {
                    flex-basis: calc(50% - #{map-get($grid-gutter-widths, xs)});
                    margin-bottom: 0;
                }
            }
            h4 {
                display: inline-block;
                margin-top: 0.5rem;
            }
            .tfc-fitrec-product .tfc-fitrec-result {
                float: right;
                margin-bottom: 20px;
            }
        }
    }
    .tabs__inner {
        white-space: nowrap;
    }
    .tabs__inner,
    .product-info-container {
        @include make-container();
        @include make-container-max-widths();

        margin: auto;

        @include media-breakpoint-up(lg) {
            padding-right: 156px;
            padding-left: 156px;
        }
    }
    .product-info-container {
        @include media-breakpoint-up(lg) {
            padding-top: 46px;
            padding-bottom: 49px;
        }
    }

    // Product Add to Cart Area (Availability, Quantity)
    .product-add-to-cart {
        margin: 0;
        padding: 10px 0 0;

        &.add-sub-product {
            border-bottom: none;
            margin-bottom: $pdp-general-padding;
        }

        .quantity {
            label {
                text-align: right;
            }
            input {
                text-align: center;
            }
        }

        .dw-apple-pay-button {
            max-width: 100%;
            width: 100%;
            border-radius: 0;
            margin-top: 16px;
            padding: 10px 15px;
            font-size: .75rem;
            font-weight: $font-weight-bold;
            height: 48px;
            background-size: 52px;
            @include media-breakpoint-down(sm) {
                margin-top: 0;
                margin-bottom: 20px;
            }
        }

    }

    .promotion {
        background-color: $gray-100;
        border-top: 1px solid #ccc;
        overflow: hidden;
        padding: 1.5em;
        .promotion-title {
            font-weight: bold;
            margin-bottom: .3rem;
            text-transform: uppercase;
        }
        .promotion-callout {
            color: $red;
            font-size: 1rem;
            .tooltip {
                font-size: .7rem;
                margin-left: .2rem;
            }
        }
    }
    .inventory {
        width: 100%;
        @include media-breakpoint-up(lg) {
            flex: 0 0 50%;
        }
    }
    .product-availability {
        align-self: center;
        flex: 0 1 58.5%;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: .25px;
        padding-bottom: 0;
        margin-bottom: 0;

        @include media-breakpoint-up(sm) {
            flex-basis: auto;
            text-align: left;
        }

        &__label {
            margin-bottom: 0;
        }
        .value {
            display: inline-block;
        }
    }
    .inventory {
        flex: 0 0 100%;
        margin-right: 22px;

        @include media-breakpoint-up(sm) {
            flex: 0 0 auto;
            max-width: 100%;
        }
    }

    // Product Set Display
    .product-set {
        .product-set-list {
            .product-set-details {
                padding-left: $pdp-general-padding;
                width: 70%;

                @include media-breakpoint-up(sm) {
                    width: 78%;
                }
            }
            .product-set-item {
                display: flex;
                padding: 1em 0 0;
            }
            .product-set-image {
                width: 30%;

                @include media-breakpoint-up(sm) {
                    width: 22%;
                }

                img {
                    width: 100%;
                }
            }
            .product-name {
                font-size: 1.3em;
                margin-bottom: 0;
                width: auto;
            }
            .product-price {
                font-size: 1rem;
                margin: .5em 0;
            }
            .attribute {
                h3 {
                    width: 25%;
                }
                .label {
                    width: 25%;
                    white-space: nowrap;
                }
            }
        }
        .product-add-to-cart {
            border-top: none;
            padding-top: 0;

            label {
                float: left;
                font-size: .75rem;
                padding-top: .3em;
                padding-right: .5em;
            }
            .product-availability {
                &__label {
                    float: none;
                    padding: top;
                }
            }
        }
        .product-set__product-add-to-cart {
            padding-left: calc(30% + 20px);

            @include media-breakpoint-up(sm) {
                padding-left: calc(22% + 20px);
            }
        }
    }
    // Product Recommendations
    .recommendations {
        clear: left;
        padding: 2em 0;
        .recommendation-tooltip {
            background: none repeat scroll 0 0 $white;
            border: 1px solid;
            left: 50%;
            position: absolute;
            text-align: left;
            top: 50%;
            width: 300px;
            z-index: 100;
        }
        h2 {
            font-size: 2em;
            font-style: italic;
            font-weight: 400;
            margin-left: 14px;
        }
        #carousel-recommendations {
            margin: auto;
            position: relative;
            overflow: hidden;
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            list-style-type: none;
            position: relative;
        }
        .product-tile {
            padding: 0 2em;
        }
        .product-name {
            font-size: 1em;
            margin: .2em 0;
            width: 100%;
        }
        .product-price {
            font-size: 1em;
        }
        .product-tile:hover .recommendation-tooltip {
            display: block !important;
        }
    }

    .inventory-availability-wrap,
    .product-add-cart__buttons-wrap {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .inventory-availability-wrap {
        > * {
            margin-bottom: 17px;
        }
    }

    .inventory-availability-callout {
        padding-bottom: 1rem;
        color: $red;
        width: 100%;
        margin: 0;
    }

    .product-actions {
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 0;

        @include media-breakpoint-up(sm) {
            justify-content: flex-start;
            margin-bottom: 15px;
        }

        .wishlist-button,
        .button {
            flex: 0 0 50%;
            padding-bottom: 18px;

            @include media-breakpoint-up(sm) {
                flex-basis: 50%;
                max-width: 50%;
            }
            @include media-breakpoint-up(md) {
                flex-basis: 50%;
                max-width: 50%;
            }
            @include media-breakpoint-up(lg) {
                padding-bottom: 0;
            }

            margin-right: 0;
        }
    }
    .product-details__detail {
        margin-bottom: 31px;
    }
    .product-features {
        ul {
            font-size: inherit;
            margin-left: 12px;
            padding-left: 15px;
            padding-top: 0;
            list-style-type: disc;
        }
        li {
            padding-bottom: 9px;
            padding-left: 5px;
        }
    }
    #product-content {
        @include media-breakpoint-up(sm) {
            padding-left: 10px;
            padding-right: 10px;
        }
        .product-price {
            .percent-savings {
                font-size: 1rem;
            }
        }
    }

    .s-pdp-recommended-products{
        .product-price{
            font-size: 1rem;
        }
    }

    .c-product-tile-carousel--3up {
        .product-tile {
            .product-brand,
            .product-name {
                font-size: 14px;
            }
            .product-price,
            .price-standard,
            .price-sales,
            .price-sales.has-standard-price,
            .percent-savings {
                font-size: 12px !important;
            }
        }
    }
}

.product-price,
.product-pricing {
    .percent-savings {
        color: $cherry-red;
        letter-spacing: .5px;
    }
}

.s-product-fashion {
    .product-primary-image {
        margin: 0;
        min-width: 100%;
        width: auto;

        @include media-breakpoint-up(sm) {
            margin: 0 -10px;
        }
        @include media-breakpoint-up(lg) {
            margin: calc(#{map-get($grid-gutter-widths, xs)} * -1) calc(#{map-get($grid-gutter-widths, xs)} * -1) 0;
        }

        .main-image {
            padding: 0;

            @include media-breakpoint-up(sm) {
                padding: 0 map-get($grid-gutter-widths, xs);
            }
            @include media-breakpoint-up(lg) {
                padding: map-get($grid-gutter-widths, xs);
            }
        }
    }
    .product-image-container,
    .product-detail {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .product-image-container {
        @include media-breakpoint-up(lg) {
            flex-basis: 66.7%;
            max-width: 66.7%;
        }
    }
    .product-detail {
        @include media-breakpoint-up(lg) {
            flex-basis: 33.3%;
            max-width: 33.3%;
        }
    }
    .product-description-link {
        font-size: 12px;
    }
    .product-variations {
        .size-attribute {
            .value {
                @include media-breakpoint-up(lg) {
                    flex-basis: 46.9%;
                    max-width: 46.9%;
                }
            }
        }
    }
    .product-add-cart__buttons-wrap {
        .button-fancy-large {
            @include media-breakpoint-up(large) {
                padding: 13px 10px;
            }
        }
    }
    .product-actions {
        @include media-breakpoint-up(md) {
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        .button {
            @include media-breakpoint-up(md) {
                flex-basis: 131px;
                margin-bottom: 18px;
                max-width: 131px;
            }
        }
        .socialsharing {
            @include media-breakpoint-up(md) {
                justify-content: flex-start;
                flex-basis: 100%;
                max-width: 100%;
            }
        }
    }
}

.product-tab {
    &--inline {
        display: inline;

        a {
            color: inherit;
            text-decoration: underline;
        }
    }
}

.s-product-wide {
    .pdp-main {
        .product-image-container,
        .product-detail {
            flex-basis: 100%;
            max-width: 100%;
        }
        .product-add-to-cart {
            border-bottom: none;
        }
    }
    .product-image-container {
        @include media-breakpoint-down(md) {
            padding: 0 28px map-get($grid-gutter-widths, xs);
        }
    }
    #product-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        @include media-breakpoint-up(md) {
            padding-left: 28px;
            padding-right: 28px;
        }
        @include media-breakpoint-up(lg) {
            margin: 0 -20px;
            padding-left: 116px;
            padding-right: 116px;
        }
    }
    .product-col-inner {
        border-bottom: 1px solid $border-color;
    }
    .product-col-inner--1 {
        @include media-breakpoint-down(md) {
            border-bottom: none;
        }
    }
    .product-actions {
        padding-left: 0;
        padding-right: 0;
        width: 100%;

        @include media-breakpoint-up(lg) {
            padding-left: $pdp-general-padding;
            padding-right: $pdp-general-padding;
            width: 50%;
        }
    }
}

.product-actions {
    @include clearfix();

    align-items: flex-start;
    color: $warm-grey;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: $pdp-general-padding 0 0;

    @include media-breakpoint-up(sm) {
        flex-wrap: nowrap;
        margin-bottom: 10px;
    }
    @include media-breakpoint-up(lg) {
        align-items: center;
        padding-top: 17px;
    }

    .socialsharing {
        display: flex;
        flex: 0 0 50%;
        max-width: 50%;
        position: relative;
        justify-content: flex-end;

        @include media-breakpoint-up(sm) {
            flex-shrink: 2;
            justify-content: flex-end;
        }
        @include media-breakpoint-up(md) {
            flex-basis: 50%;
            max-width: 50%;
            padding-top: 0;
        }

        @include media-breakpoint-down(sm) {
            padding-bottom: 18px;
        }

        .dropdown-drawer {
            padding: 5px 10px;


        }
    }
    a {
        color: inherit;
        font-size: .81rem;
        margin-right: 10px;

        &:hover {
            color: $body-color;
        }
        &.share-icon {
            display: inline-block;
        }
    }
    .share-icon__svg {
        height: 23px;
        vertical-align: middle;
        width: 23px;

        svg {
            max-height: 100%;
            max-width: 100%;
        }
    }
    .share-link,
    .share-icon {
        &:last-child {
            margin-right: 0;
        }
    }
    .share-link {
        &:last-child {
            a {
                margin-right: 0;
            }
        }
    }
}
.at-resp-share-element a.at-share-btn {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
}
.product-actions__svg {
    display: inline-block;
    height: 24px;
    vertical-align: middle;
    width: 24px;

    use {
        stroke: $warm-grey;
    }
}

.product-info__header {
    font-weight: 600;

    @include media-breakpoint-up(lg) {
        font-size: 24px;
    }

    a {
        color: inherit;
    }
}
.product-info__headers-wrap {
    margin-bottom: 40px;

    li {
        display: inline-block;
        list-style: inherit;
        margin-right: 21px;
    }
}

.main-image {
    display: block;
}
.primary-image {
    max-width: 100%;
    width: 100%;
}

.product-thumbnails {
    display: none;
    flex: 0 1 120px;
    min-width: 120px;
    overflow: hidden;

    @include media-breakpoint-down(md) {
        display: none !important;
    }

    .slick-arrow{
        position: relative;
        left: 50px;
        margin: 5px;
    }

    // thumbnail
    li {
        @include size(100px, 102px);

        border-bottom: 3px solid transparent;
        margin-bottom: 8px;
        margin-right: .5rem;
        overflow: hidden;
        padding-top: 0;
        &.selected {
            border-color: $black;
            border: 1px solid $black;
        }

        img {
            min-height: 100%;
            min-width: 100%;
        }
    }
    // thumbnail image
    img {
        box-sizing: border-box;
        cursor: pointer;
        max-width: 100%;
        height: auto;
    }
    h2 {
        margin: 1rem 0 .3rem;
    }
}
.product-thumbnails__link {
    a {
        align-items: center;
        background-color: $white-two;
        border-radius: 50%;
        color: $warm-grey;
        display: flex;
        flex-direction: column;
        font-size: 11px;
        height: 100%;
        justify-content: flex-end;
        letter-spacing: .92px;
        line-height: 2.5;
        padding-bottom: 18px;
        text-align: center;
        text-transform: uppercase;
        width: 100%;

        &::before {
            border-bottom: 9px solid transparent;
            border-left: 15px solid $primary;
            border-top: 9px solid transparent;
            content: 'test';
            display: block;
            font-size: 0;
            height: 0;
            line-height: 0;
            margin: 0 auto 4px;
            padding: 0 !important;
            width: 0;
        }
    }
}

.rating {
    .product-info & {
        font-size: 2em;
    }
}

.pdpForm {
    margin-bottom: 0;

    fieldset {
        margin-bottom: 1rem;
    }
}

.product-variations,
.product-options {
    .color-product-price {
        display: block;
        font-size: 1rem;
        margin: 0 0 10px 0;
        width: 75%;
    }
    .attribute {
        margin: 0 0 14px;
        overflow: hidden;
        padding: 0;
        position: relative;

        @include media-breakpoint-up(lg) {
            margin-top: 13px;
        }

        h3 {
            font-weight: bold;
            margin-bottom: .3rem;
            text-transform: uppercase;
        }
        ul {
            float: right;
            width: 100%;
        }
        .value, .tfc-fitrec-product.tfc-product-main-widget {
            display: flex;
            width: 100%;

            select {
                @include media-breakpoint-up(lg) {
                    flex: 0 0 48%;
                    max-width: 48%;
                }
            }
        }
        .label.with-size-chart-link,
        .size-chart-link {
            flex: 0 0 48%;
            max-width: 48%;

            @include media-breakpoint-up(md) {
                flex-basis: 48%;
                max-width: 48%;
            }
        }
        .label {
            display: inline-block;
            font-size: 14px;
            font-weight: 400;
            line-height: 2;
            margin-bottom: 5px;

            @include media-breakpoint-up(sm) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .size-chart-link {
            @include media-breakpoint-up(sm) {
                flex-basis: 48%;
                max-width: 48%;
            }
        }
        .selected-value {
            border: 0 none;
            color: $body-color;
            font-weight: 600;

            @include media-breakpoint-up(lg) {
                padding-top: .8em;
            }
        }
        .size-chart-link {
            clear: both;
            float: none;
            a {
                border-color: $white !important;
                padding: 0;

                &:hover {
                    text-decoration: none;
                }

                span {
                    text-decoration: underline;
                }
            }
        }
    }
    .attribute {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .size-chart-link {
            font-size: 13px;
            text-align: right;
            margin: 0 0 5px 0 !important;

            @include media-breakpoint-up(sm) {
                margin-top: 7px;
                margin-bottom: 10px;
                text-align: left;
            }

            a {
                color: inherit;
            }

            .js-product-tab {
                text-align: right;

                .tab-label {
                    font-size: 14px;
                }
            }
        }
        .size-chart-link__svg {
            height: 30px;
            vertical-align: middle;
            width: 30px;

            &.svg-symbol {
                display: none;
            }
        }
        .tfc-fitrec-product.tfc-product-main-widget {
            margin-top: 10px;
            order: 3;
            flex-basis: 100%;
            width: 100%;
            .tfc-fitrec-result {
                width: 100%;
                font-size: 13px;
            }
            .tfc-size {
                font-weight: unset;
            }
            .tfc-button-part > button.tfc-popup-click-open {
                width: auto;
                border: none;
                text-align: left;
                padding: 0;
                display: inline;

                &:focus {
                    box-shadow: none;
                }

                .tfc-logo {
                    display: inline-block;
                    position: static;
                    top: unset;
                    left: unset;
                }
                .tfc-valign-wrapper {
                    display: inline;
                    width: unset;
                    text-decoration: underline;
                }
            }
        }
    }
    .size-attribute {
        justify-content: space-between;

        @include media-breakpoint-up(sm) {
            justify-content: flex-start;
        }

        .label {
                flex: 0 0 48%;

            @include media-breakpoint-up(sm) {
                flex-basis: 100%;
            }
        }
        .value {
            @include media-breakpoint-up(sm) {
                flex-basis: 48%;
                margin-right: 16px;
            }
            @include media-breakpoint-up(md) {
                margin: 0;
                margin-right: 11px;
                max-width: 48%;
        }

            select {
                flex-basis: 100%;
                max-width: 100%;
            }
        }
    }
    .option-dropdown__label {
        display: block;
        font-size: 14px;
        margin-bottom: 6px;
    }
    .swatches {
        margin-top: -6px;

        &.size {
            padding-left: 2px;

            li {
                margin-right: 7px;
                margin-bottom: 8px;
                border: 1px solid $light-grey;
                border-radius: 0;

                &.selected,
                &:hover {
                    border-color: $black;
                    transition: box-shadow .2s ease;

                    a {
                        text-decoration: none;
                    }
                }

                &.selectable-partial a {
                    color: #ddd9d7;
                }

                &.unselectable,
                &.selectable-partial {
                    a {
                        &::after {
                            width: 1px;
                        }
                    }
                }

                &.selected {
                    box-shadow: 0 0 0 1px $black !important;
                }

                &:hover {
                    box-shadow: 0 0 0 0 $black;
                }
            }

            .swatchanchor {
                min-width: 50px;
                width: auto;
                line-height: 2.5;
                border-radius: 0;
                padding: 0 8px;
                color: $black;
                border: none;
                margin: 0;
            }
        }
    }
    .swatches li {
        float: left;
        &:hover,
        &.selected {
            a {
                border-color: $black;
            }
        }
        &.unselectable, &.selectable-partial {

            img {
                opacity: .5;
            }

            a {
                color: $gray-200;
                overflow: hidden;
                position: relative;

                &::before,
                &::after {
                    content: '';
                    height: 100%;
                    position: absolute;
                    top: 0;
                }
                &::before {
                    border-radius: inherit;
                    left: 0;
                    width: 100%;
                    z-index: 2;
                }
                &::after {
                    background-color: rgba(0, 0, 0, 0.22);
                    height: 100%;
                    left: 50%;
                    transform: translateX(-50%) rotateZ(45deg);
                    width: 2px;
                    z-index: 3;
                }
            }
            &:hover {
                a {
                    border-color: $gray-200;
                }
            }
        }
        a {
            display: block;
            line-height: 3.5;
            margin: 0 7px 0 0;
            text-align: center;
            white-space: nowrap;
            color: $black;
            @include media-breakpoint-up(lg) {
                line-height: 2.7;
                min-height: 2rem;
                min-width: 2rem;
            }
        }
    }
    .swatchanchor {
        @include size(38px);
        border: 2px solid transparent;
        border-radius: 100%;
        display: block;
        overflow: hidden;
        padding: 0;

        img {
            @include size(100%);
            border-radius: 100%;
            border: 3px solid transparent;
            display: block;
        }
    }
    .color li {
        a {
            line-height: 0;
        }
    }
    select {
        background-color: $white;
        border: 1px solid $gray-200;
        border-radius: 0;
        width: 100%;
        margin-left: 0;
    }
}

.product-add-cart__buttons-wrap {
    justify-content: space-between;
    margin: 0;

    button {
        flex: 0 0 100%;

        @include media-breakpoint-up(sm) {
            flex-basis: 48%;
            max-width: 48%;
        }

        @include media-breakpoint-up(md) {
            &#find-in-store {
                max-height: 48px;
            }
        }
    }
}
.add-to-cart,
#add-to-cart {
    margin-bottom: $pdp-general-padding;

    @include media-breakpoint-up(sm) {
        margin: 0;
    }
}

// Tabs using just CSS, no javascript required
.tabs {
    display: none;
    position: relative;

    .tab {
        background-color: $white-two;
        min-width: 132px;
        text-align: center;
        vertical-align: bottom;
    }

    .tab-label {
        border-top: 3px solid $white-two;
        color: inherit;
        display: block;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 1.5px;
        line-height: normal;
        margin-bottom: 0;
        position: relative;
        text-transform: uppercase;
        top: 0;
        transition: all 0.25s;
        @include media-breakpoint-up(lg) {
            cursor: pointer;
            padding: 18px 30px 16px;
            &.is-active-tab,
            &:hover {
                border-top-color: $primary;
            }
            &:hover {
                top: -0.25rem;
                transition: top 0.25s;
            }
        }
    }
}
.tab {
    margin-right: 12px;

    @include media-breakpoint-up(lg) {
        display: inline-block;
        float: none;
    }
    .tab-switch {
        display: none;
        &:checked {
            + .tab-label {
                @include media-breakpoint-up(lg) {
                    background: #fff;
                    border-top-color: $primary;
                    color: $black;
                    font-weight: 700;
                    top: -0.0625rem;
                    transition: all 0.35s;
                    z-index: 1
                }
            }
            + label + .tab-content {
                opacity: 1;
                transition: all 0.35s;
                z-index: 2;
            }
        }
    }
    .tab-content {
        padding: 1em 0;
        text-align: left;
        z-index: 1;
        @include media-breakpoint-up(lg) {
            opacity: 0;
            transition: all 0.35s;
            width: 100%;
        }
    }
}
.tab-content-wrap {
    &.is-active-tab-content {
        display: block !important;
    }

    h3 {
        font-family: inherit;
        font-size: 20px;
        line-height: normal;
        margin-bottom: $pdp-general-padding;

        @include media-breakpoint-up(lg) {
            font-size: 24px;
        }
        }
    h4 {
        line-height: 1.2;
    }
}

.product-description-videos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 20px;

    h4 {
        width: 100%;
    }
}
.product-description-video {
    flex: 0 0 100%;
    margin-bottom: $pdp-general-padding;

    &:last-child {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
        flex: 0 0 48.4%;
    }
}
.product-description-video__inner {
    padding-bottom: 55.94%;
    position: relative;

    @include media-breakpoint-up(lg) {
        padding-bottom: 56.25%;
    }

    iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.pdp-gift-cert {
    @include make-container();
    @include make-container-max-widths();

    font-weight: 300;
    padding-bottom: map-get($spacers, 4);
    padding-top: map-get($spacers, 4);

    @include media-breakpoint-up(lg) {
        padding: 46px 156px 49px;
    }

    &__outer {
        background-color: $tertiary;
    }
    &__inner {
        @include make-row();
    }
    &__lookup,
    &__top-up {
        padding: 10px 5px;
        width: 100%;

        @include media-breakpoint-up(sm) {
            padding: map-get($grid-gutter-widths, xs) 30px;
        }
        @include media-breakpoint-up(md) {
            padding: 10px;
        }
        @include media-breakpoint-up(lg) {
            padding: map-get($grid-gutter-widths, xs);
        }

        h2 {
            font-family: inherit;
            font-size: 20px;
            line-height: normal;
            margin-bottom: 20px;
            text-transform: uppercase;

            @include media-breakpoint-up(lg) {
                font-size: 24px;
            }
        }
    }
    &__lookup {
        @include media-breakpoint-up(lg) {
            @include make-col(8);
        }
    }
    &__top-up {
        @include media-breakpoint-up(lg) {
            @include make-col(4);
        }

        .svg-symbol {
            height: 24px;
            width: 24px;
        }
    }

    .check-balance {
        border-bottom: none;

        @include media-breakpoint-down(md) {
            padding-bottom: 0;
        }
    }
    .icon-link {
        color: inherit;

        img {
            margin-right: 5px;
            max-height: 20px;
        }
    }
}

.product-variant {
    &__pricing {
        display: flex;
        padding-bottom: 1rem;
    }

    &__strikethrough {
        text-decoration: line-through;
        color: $gray-500;
    }

    &__sale {
        padding-left: .5rem;
    }
}

.product-price-sales-title {
    padding-top: .5rem;
    font-size: 14px;
}

.buttonContainer {
    max-width: 100%;
    width: 100%;

    @include media-breakpoint-up(sm) {
        max-width: 48%;
        width: 48%;
    }

    .add-to-cart {
        max-width: 100%;
        width: 100%;
    }
}

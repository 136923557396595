.pt_product-search-noresult,
.pt_content-search-noresult,
.pt_customer-service {
    h1 {
        margin-bottom: map-get($grid-gutter-widths, lg);
    }
}
.form-content-row {
    @include media-breakpoint-up(lg) {
        @include make-col(11);
    }

    > * {
        padding-left: map-get($grid-gutter-widths, xs);
        padding-right: map-get($grid-gutter-widths, xs);
    }

    .button-fancy-large {
        min-width: 186px;
        padding: 15px;
    }
}
.half-page-col {
    font-size: 13px;
    letter-spacing: .25px;
    margin-bottom: map-get($spacers, 5);

    h2 {
        font-family: inherit;
        font-size: 22px;
        font-weight: normal;
        letter-spacing: normal;
        margin-bottom: 12px;
    }
    p {
        line-height: normal;
        margin-bottom: 17px;
    }
}
#secondary h1 {
    font-size: 1.75em;
    font-style: italic;
    font-weight: 400;
    margin: 0 0 14px;
}

.primary-content {
    dl {
        clear: both;
        overflow: hidden;
        dt {
            clear: both;
            float: left;
            margin: 2% 0 0;
            min-width: 15%;
        }
        dd {
            float: left;
            margin: 2% 0 0;
            padding: 0 0 0 5%;
        }
    }
}

.content-header {
    margin: 0 0 14px !important;

    span {
        font-size: 0.75em;
        font-style: normal;
        font-weight: 400;
    }
}

.back {
    display: block;
    margin: 0 0 10px;
}
.site-map {
    column-count: 1;

    @include media-breakpoint-up(sm) {
        column-count: 2;
    }
    @include media-breakpoint-up(md) {
        column-count: 3;
    }
}
.column-list {
    &__list {
        padding: 0;
    }
    &__header {
        margin-bottom: 3px;

        &__link {
            color: inherit;

            &:hover {
                color: inherit;
            }
        }
    }
    &__item {
        list-style: none;
        padding: 0 6px;

        @include media-breakpoint-up(md) {
            padding: 0;
        }
    }
    &__link {
        color: inherit;
        display: inline-block;
        font-size: 12px;
        padding: 4px 0;
        word-break: break-word;
    }
}

$ns: "c-product-tile-carousel";

.#{$ns} {
    margin: 0 -10px;
    width: calc(100% + 20px);

    @include media-breakpoint-up(lg) {
        margin: 0 -#{map-get($grid-gutter-widths, xs)};
        width: calc(100% + #{2 * map-get($grid-gutter-widths, xs)});
    }

    &__header {
        margin-top: map-get($spacers, 5);
        margin-bottom: map-get($spacers, 5);

        h2, h3, h4 {
            font-family: $headings-font-family;
            font-size: 30px;
            font-weight: 600;
            letter-spacing: -.2px;
            text-align: center;
        }
    }

    .slick-track {
        display: flex;
    }

    .slick-slide {
        height: inherit;
    }

    .product-tile {
        padding: 0 22px;
        margin-bottom: 4px;

        @include media-breakpoint-up(md) {
            margin-bottom: 20px;
        }

        .product-brand,
        .product-name {
            display: inline-block;
            font-size: inherit;
            margin-bottom: 0;
            text-transform: none;

            @include media-breakpoint-down(sm) {
                line-height: 1.35;
            }
        }

        .product-price {
            font-size: 12px;
            line-height: normal;
            margin-bottom: 15px;
            .price-standard {
                color: $gray-500;
                font-weight: 100;
                text-decoration: line-through;
                display: inline-block;
                margin-right: 2px;
            }
            .price-tiered {
                color: $red;
                font-size: .6em;
                line-height: 1.5em;
            }
            .price-sales.has-standard-price.final-flag {
                display: none;
            }
        }
    }

    &__rule {
        display: none;
        border-top: 5px solid $border-color;
        margin: 0;
    }

    .s-pdp-recommended-products &,
    .s-complete-the-look & {
        overflow: hidden;
        margin-bottom: map-get($spacers, 3);
        padding-bottom: 0;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: map-get($spacers, 5);

        @include media-breakpoint-up(sm) {
            padding-left: 10px;
            padding-right: 10px;
        }
        @include media-breakpoint-up(lg) {
            @include make-container();
            @include make-container-max-widths();
        }

        h2 {
            margin: 0 auto 30px;
            text-align: center;
        }

        .slick-dotted {
            margin-bottom: 67px;
        }

        &__rule {
            display: block;
        }

    }

}

.pt_product-search-result,
.pt_content-search-result {
    .slot-grid-header {
        padding-bottom: 1em;
        padding-top: 0 !important;

        .html-slot-container {
            margin-bottom: map-get($spacers, 4);
        }
    }
}
.pt_product-search-result {
    .primary-content {
        .content-header {
            font-style: normal;
            margin-bottom: map-get($grid-gutter-widths, xs);
            padding: 0 10px;

            @include media-breakpoint-up(lg) {
                padding: 0;
            }
        }
    }
}

.pt_content-search-result {
    .primary-content {
        .pagination {
            padding: 0;
        }
    }
    .folder-content-list {
        margin-bottom: 0;
        margin-top: -#{map-get($spacers, 4)};
        padding: 0;
    }
    .search-result-options--bottom {
        margin-bottom: 10px;
    }
}
.pt_product-search-noresult {
    .section-header {
        padding-left: 0;
        padding-right: 0;
    }
    .simple-search {
        @include media-breakpoint-down(xs) {
            margin-top: 12px;
        }
    }
}

.category-main-banner img {
    max-width: 100%;
}

.no-hits-message {
    margin-bottom: map-get($spacers, 4);
}
.no-hits-search-term,
.no-hits-search-term-suggest {
    color: $red;
    font-style: italic;
}

.no-hits-footer {
    margin: 20px 0;
    padding: 20px 0;
}

.search-result-bookmarks,
.search-result-options,
.pt_product-search-result .slot-grid-header,
.pt_product-search-result .content-header {
    padding: 1em 10px;

    @include media-breakpoint-up(lg) {
        padding-left: 0;
        padding-right: 0;
    }
}
.search-result-options {
    font-size: 13px;
    letter-spacing: .25px;
    overflow: hidden;

    &--bottom {
        .no-results-text,
        .back-to-product-results {
            display: none;

            @include media-breakpoint-up(lg) {
                display: block;
            }
        }

        &.infinite-scroll-enabled {
            display: none;
        }
    }

    .sort-by {
        padding-bottom: 1em;
        @include media-breakpoint-up(lg) {
            float: left;
            margin-right: .5em;
            padding-bottom: 0;
        }
    }
    .no-results-text,
    .items-per-page {
        float: left;
        margin-right: .5em;
    }
    form {
        margin-bottom: 0;
    }
    label {
        display: none;
    }
    .input-select--alt {
        margin-right: 0;
        font-style: normal !important;
        width: 100%;
        min-width: 150px;
        padding: 10px 10px;
        border: 1px solid $light-grey;
        font-size: 13px;
    }
    .pagination {
        text-align: center;
        vertical-align: top;

        .results-hits {
            color: $gray-600;
            display: inline-block;
            margin-right: 2px;
            vertical-align: top;

            @include media-breakpoint-up(lg) {
                display: block;
                float: right;
                margin-right: 10px;
            }
        }
        ul {
            align-items: center;
            border: none;
            border-radius: 5px;
            background-color:transparent;
            display: inline-flex;
            list-style: none;
            margin: 0;
            padding: 0;

            @include media-breakpoint-up(lg) {
                display: flex;
                float: right;
            }
        }
        li {
            border: none;
            float: left;
            padding: 0 12px;

            @include media-breakpoint-up(lg) {
                padding: 0 7px;
            }

            a,
            &.current-page,
            i {
                display: block;
                font-size: inherit;
                height: auto;
                line-height: inherit;
                text-align: center;
                width: auto;
            }
            &.current-page {
                border-bottom: 1px solid $blue;
                color: $blue;
                margin: 0 10px;
                padding: 0 1px;

                @include media-breakpoint-up(lg) {
                    margin: 0 6px;
                }
            }
            a {
                color: inherit;
            }

            &.pagination {
                &__first,
                &__last,
                &__prev,
                &__next {
                    padding: 0 8px;
                }
            }

        }


        .svg-symbol {
            display: block;
            height: 1rem;
            width: .6rem;

            &.svg-angle-right,
            &.svg-angle-left {
                width: .4rem;
            }

        }

    }
    .sort-by,
    .items-per-page {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }
}
.search-result-bookmarks {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    margin: 0 0 map-get($spacers, 4);
    text-align: center;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        padding-bottom: 3px;
    }

    a {
        border-bottom: 2px solid transparent;
        color: inherit;
        margin: 0 13px;
        padding: 2px 0;

        &:hover {
            border-bottom-color: $blue;
            text-decoration: none;
        }
    }
}

.search-result-options--top {
    margin-bottom: map-get($grid-gutter-widths, xs);

    .pagination {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
        }

    }

}

.search-result-items {
    padding: 0;

    ul {
        list-style: none outside none;
    }

    &.tiles-container {
        @include make-row();
    }

    &.slots-container {
        display: block;
    }

    .grid-tile {
        @include make-col-ready();
        @include make-col(6);
        list-style: none outside none;
        margin-bottom: map-get($spacers, 4);
        padding: 0 10px;

        @include media-breakpoint-up(md) {
            @include make-col(4);
        }

    }

    &.slots-container {

        display: -ms-grid;
        display: grid;

        grid-template-columns: 1fr 1fr;
        @include media-breakpoint-up(md) {

            -ms-grid-columns: 1fr 1fr 1fr;
            -ms-grid-rows: 1fr 1fr 1fr 1fr;
            grid-template-columns: 1fr 1fr 1fr;
        }

        .grid-tile {
            max-width: 100%;
            flex: none;



            @include media-breakpoint-up(md) {
                &.column-1{
                    -ms-grid-column: 1;
                }
                &.column-2{
                    -ms-grid-column: 2;
                }
                &.column-3{
                    -ms-grid-column: 3;
                }
                &.column-4{
                    -ms-grid-column: 4;
                }
                &.row-1{
                    -ms-grid-row: 1;
                }
                &.row-2{
                    -ms-grid-row: 2;
                }
                &.row-3{
                    -ms-grid-row: 3;
                }
                &.row-4{
                    -ms-grid-row: 4;
                }

            }
        }


        .columnonebyone {
            /* 1 x 1 */
            //nothing needed yet
        }
        .columntwobyone {
            /* 2 x 1 */
            -ms-grid-column-span: 2;
            grid-column: span 2;

        }

        .columnthreebyone {
            /* 3 x 1 */
            grid-column: span 2;
            @include media-breakpoint-up(md) {
                -ms-grid-column-span: 3;
                grid-column: span 3;
            }
        }
        .columnonebytwo{
            -ms-grid-row-span: 2;
            grid-row: span 2;
        }
        .columntwobytwo {
            /* 2 x 2 */
            -ms-grid-column-span: 2;
            grid-column: span 2;
            -ms-grid-row-span: 2;
            grid-row: span 2;
        }

        .columnthreebytwo {
            /* 3 x 2 */
            -ms-grid-row-span: 2;
            grid-row: span 2;
            grid-column: span 2;
            @include media-breakpoint-up(md) {
                -ms-grid-column-span: 3;
                grid-column: span 3;
            }
        }
    }

    .invisible {
        display: none;
    }

}

.search-result-content {
    clear: both;
    .product-price {
        .price-standard {
            color: #999;
            font-weight: 100;
            text-decoration: line-through;
            display: inline-block;
        }
    }
}

.search-promo {
    padding: 20px;
}

#results-content {
    padding-top: 30px;
}

.product-tile,
.folder-content-list {
    .name-link {
        color: $body-color;
        display: inline-block;
        font-size: 13px;

        @include media-breakpoint-up(lg) {
            font-size: 16px;
        }

        .product-brand {
            font-weight: 600;
        }
        .product-name,
        .content-title {
            font-weight: $font-weight-light;

            @include media-breakpoint-up(lg) {
                line-height: 1.5;
            }
        }
    }
}
.folder-content-list {
    clear: both;
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    padding: 0;
    width: auto;

    @include media-breakpoint-up(lg) {
        min-width: 100%;
    }

    li {
        background-color: $gray-100;
        border: 1px solid $gray-400;
        flex-basis: 100%;
        list-style-type: none;
        margin: 0 10px 15px;
        padding: 1rem;

        @include media-breakpoint-up(lg) {
            flex-basis: calc(50% - 20px);
            margin: 10px;
            max-width: calc(50% - 20px);
        }
    }
    p {
        line-height: 1.1rem;
    }
    .content-title {
        display: block;
    }
    .content-excerpt {
        font-size: 13px;
    }
    .readmore {
        font-size: 12px;
        font-style: italic;
    }
}

.no-results {
    padding: 20px;
}

.toggle-grid {
    display: block;
    float: left;
    margin-right: 0.5em;
    i {
        cursor: pointer;
        margin-right: 0.3em;
    }
    [data-option="column"] {
        color: $body-color;
    }
    &.wide {
        [data-option="wide"] {
            color: $body-color;
        }
        [data-option="column"] {
            color: $gray-600;
        }
    }
}
#grid-sort-header {
    &:focus,
    &:active {
        outline: $focus-color auto 1px;

        @include media-breakpoint-down(lg) {
            font-size: 13px;
        }
    }
}

.infinite-scroll-div {
    margin-bottom: 15px;

    .infinite-scroll-messaging {
        text-align: center;
    }
}

.progress-bar {
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    background-color: $gray-300;
}

.progress-percent {
    width: 1%;
    height: 10px;
    background-color: $primary;
}

.search-suggestion-wrapper {
    background-color: $white;
    border-top: 2px solid $border-color;
    box-shadow: 0 20px 30px -5px rgba(116, 116, 116, 0.5);
    color: $body-color;
    font-size: 13px;
    max-height: calc(100vh - 165px);
    overflow: auto;
    position: absolute;
        z-index: 1;
    width: 100%;

    @include media-breakpoint-up(lg) {
        max-height: calc(100vh - 175px);
    }

    a {
        &, &:hover {
            color: inherit;
        }
    }

    .phrase-suggestions,
    .product-suggestions {
        padding: 15px map-get($grid-gutter-widths, xs) 20px;
    }

    &.full {

        @include media-breakpoint-up(sm) {
            display: flex;
        }

        @include media-breakpoint-up(lg) {
            border: 0;
            display: block;
            margin-left: -75%;
            width: 175%;
        }

        .phrase-suggestions {

            @include media-breakpoint-up(sm) {
                order: 1;
                padding: 15px 0 20px map-get($grid-gutter-widths, xs);
                width: 42.8571429%;
            }

            @include media-breakpoint-up(lg) {
                border-top: 0;
                float: left;
            }

        }
        .product-suggestions {
            padding: 15px map-get($grid-gutter-widths, xs) 20px;

            @include media-breakpoint-up(sm) {
                order: 2;
                padding: 15px map-get($grid-gutter-widths, xs) 20px 15px;
                width: 57.1428571%;
            }

            @include media-breakpoint-up(lg) {
                border-top: 2px solid $border-color;
                float: right;
            }

        }
    }

    .hitgroup {
        margin-bottom: 23px;

        &:last-child {
            margin-bottom: 0;
        }

        .header {
            font-size: 15px;
            font-weight: 600;
            letter-spacing: normal;
        }

        .hit {
            border-bottom: 1px solid $light-grey;
            display: inline-block;
            line-height: 1.3;
            margin-bottom: 6px;
            text-decoration: none;
            transition: border-color .2s;

            &:hover {
                border-color: $body-color;
            }

        }

    }

    .search-phrase {
        margin-bottom: 12px;

        a {
            border-bottom: 1px solid $light-grey;
            line-height: 1.3;
            text-decoration: none;
            transition: border-color .2s;

            &:hover {
                border-color: $body-color;
            }
        }

    }

}

.product-suggestion {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    .product-link {
        display: flex;
        @include media-breakpoint-down(lg) {
            align-items: center;
        }
    }

    .product-image {
        flex: 0 0 120px;
        max-width: 120px;

        @include media-breakpoint-up(md) {
            flex: 0 0 85px;
            max-width: 85px;
        }

        @include media-breakpoint-up(lg) {
            flex: 0 0 85px;
            max-width: 85px;
        }

        img {
            display: block;
            height: auto;
            max-width: 100%;
            width: 100%;
        }

    }

    .product-details {
        padding-left: 20px;

        .product-price {
            font-size: 12px;
        }

        .product-brand {
            font-weight: 700;
        }

    }

}

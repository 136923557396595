.js {
    .loader {
        background-attachment: fixed;
        background-color: rgba($white, .7);
        background-image: url("../images/loading-small.gif");
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
            left: 0;
            top: 0;
            z-index: 10;
        width: 100%;
        height: 100%;
    }

    .search-result-content {
        .loader {
            @include media-breakpoint-up(lg) {
                // offset for sidebar
                background-position-x: 55%;
            }

            @include media-breakpoint-up(xl) {
                // offset for sidebar
                background-position-x: calc(50% + 164px);
            }


        }
    }

}

.tooltip {
    cursor: help;
    position: relative;
    &.product-tile {
        cursor: pointer;
    }
}

.tooltip-content {
    display: none;
}

.ui-tooltip.ui-widget-content {
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 20px 30px -5px rgba(116, 116, 116, 0.4);
    color: inherit;
    font-size: 13px;
    letter-spacing: .25px;
    max-width: 300px;
    padding: 13px;
    pointer-events: none;
    width: max-content;
    z-index: $zindex-tooltip;

    p {
        margin-bottom: 5px;
    }
}

.ui-tooltip-content {
    padding: 0.3em;

    .recommendation-tooltip-header {
        font-size: 1.2em;
        font-weight: bold;
        padding: 0.5em;
        .product-price {
            font-weight: 400;
            .price-standard {
                color: $gray-500;
                text-decoration: line-through;
            }
        }
    }
    .recommendation-tooltip-description,
    .recommendation-tooltip-attributes {
        padding: 0.8em;
    }
    .shipping-method-cost,
    .surcharge-product,
    .promo {
        text-align: right;
        padding-bottom: .3rem;
    }
    .shippingtotal {
        clear: right;
        text-align: right;
        margin-top: .3rem;
        .value {
            border-top: 1px solid;
            padding-top: .3rem;
        }
    }
}

.pt_brand {
    .breadcrumb {
        margin-bottom: 38px;
    }
}
.brands-landing-header {
    font-size: 47px;
    letter-spacing: -.9px;
    line-height: 1.06;
    margin-bottom: 50px;
    text-align: center;
}
.brand-logo-wrap {
    margin-bottom: 42px;
    padding: map-get($grid-gutter-widths, lg) map-get($grid-gutter-widths, xs) 0;
    text-align: center;
}
.brand-banner-wrap,
.brand-carousel,
.pt_brand .category-header {
    padding: 0 10px;

    @include media-breakpoint-up(lg) {
        padding: 0;
    }
}
.brand-banner-wrap,
.brand-carousel {
    margin-bottom: map-get($grid-gutter-widths, xs);

    @include media-breakpoint-up(lg) {
        margin-bottom: map-get($grid-gutter-widths, lg);
    }
}
.brand-banner-wrap {
    display: block;
}
.brand-carousel {
    .slick-list {
        overflow: hidden;
    }
    .slick-slide {
        float: left;
    }
}

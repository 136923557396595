$table-spacer: map-get($spacers, 4);
.table {
    margin-bottom: $table-spacer;

    tbody {
        th,
        td {
            border-bottom: none;
            border-right: 1px solid $table-border-color;
            border-top: none;
        }
    }
    th,
    td {
        font-size: 12px;
        padding: 15px 25px;
    }
    th {
        font-weight: 600;
        text-transform: uppercase;
    }
}

// Force table-bordered to display border in table-responsive context.
// Also, shifts bottom margin down below
.table-responsive {
    margin-bottom: $table-spacer;

    .table {
        &.table-bordered {
            border: $table-border-width solid $table-border-color;
            margin-bottom: 1px; // iOS adjustment
        }
    }

}

.table-bordered-x {
    border: 0;

    th, td {
        padding-right: map-get($spacers, 2);
        padding-left: map-get($spacers, 2);
    }

    thead {

        tr {
            border: 0;
        }

        th {
            border: 0;
            border-bottom: $table-border-width solid $table-border-color;
        }

    }

    tbody {
        th, td {
            border: 0;
            border-top: $table-border-width solid $table-border-color;
        }
        tr:first-child {
            td {
                border: 0;
            }
        }
    }


}

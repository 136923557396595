:root {
    --header-height: 85px;
}

.has-sticky-elem {
    footer, *.bottom-main-above-footer {
        position: relative;
        z-index: $zindex-footer;
    }
}
.is-sticky {
    @include media-breakpoint-up(lg) {
        max-height: calc(100vh - 110px); //Fallback assume header height is 85px;
        max-height: calc(100vh - 25px - var(--header-height, 85px));
        overflow: auto;
        padding: inherit;
        position: fixed;
        z-index: $zindex-sticky-elem;
    }
}


.account-nav-asset {
    margin-top: map-get($spacers, 4);
}

.pt_account {
    h1,
    .h1 {
        color: $black;
        font-weight: $headings-font-weight;
        letter-spacing: -0.3px;
        line-height:  43px;
    }
    h4,
    .h4 {
        color: $black;
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-normal;
    }

    h6,
    .h6 {
        color: $black;
        font-size: $font-size-base;
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-normal;
    }

    p {
        font-size: 13px;
        margin: 0.63rem 0;
    }

    .button {
        display: inline-block;
        font-family: "Nunito Sans", sans-serif;
        line-height: 44px;
        height: 46px;
        min-width: 174px;
        padding: 0px 25px;
    }

    .product-list-item {
        .sku, .attribute, .price{
            font-size: .813em;
        }
        .attribute .label:after,
        .price .label:after {
            content: ": ";
        }
    }


    input[type=text], input[type="password"] {
        height: 46px;
        max-width: 100%;
        padding: 0px 10px 0px 12px;
        @include media-breakpoint-up(lg) {
            max-width: 427px;
        }
    }

}

h1 .account-logout {
    font-size: 0.6em;
    float: none;
}

.registration-selection {
    input, label {
        display: inline;
        float: none;
    }
}

.create-account {
    .c-form-row.countryselect {
        display: none;
    }
}

.address-list,
.payment-list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    li {
        font-size: 0.813rem;
        list-style-type: none;
        padding: 1em 2% 0.5rem;
        width: 100%;
        @include media-breakpoint-up(md) {
            width: 50%;
        }
        &.default {
            background-color: $gray-100;
            border: 1px solid $gray-400;
            border-radius: 4px;
            margin: 2em 0 1rem 0;
            width: 100%;
        }
        address {
            color: $gray-600;
            font-style: normal;
        }
        a,
        .button-text {
            line-height: 2rem;
            padding-right: .2rem;
        }
    }
    .mini-address-title {
        font-style: italic;
        line-height: 2rem;
    }
    .cc-owner {
        font-weight: 700;
    }
}

a.address-create:hover, a.add-card:hover{
    color: $white;
    text-decoration: none;
}

.address-details, .add-card-details {
    .c-form-row + .c-form-row {
        margin-top: 0.6rem;
    }
    .input-text, .input-textarea, .input-select {
        max-width: 98%;
    }
    .c-form-row{
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        .form-caption {
            display: inline-block;
            order: 2;
            margin-left: 48%;
            text-align: right;
            width: 100%;
            @include media-breakpoint-up(lg) {
                width: 50%;
            }
        }
        .form-field-tooltip {
            display: inline-block;
            font-size: 0.75rem;
            order: 3;
            margin-top: -19px;
            text-align: left;
            width: 100%;
            @include media-breakpoint-up(lg) {
                width: 48%;
            }
        }
    }

    .form-row-button {
        margin-top: 1.5rem;
    }

    span.error {
        position: relative;
        &:after {
            left: 95%;
        }
    }
}

.primary-content .landing {
    dt, dd {
        float: none;
        padding: 0px;
    }

    dt {
        font-size: 1.3rem;
        margin-top: 0px;
    }

    dd {
        font-size: 0.813rem;
        margin: 0px;
        padding-top: 0;
    }
}

.address-details .form-row-button, .add-card-details .form-row-button {
    margin-bottom: 1.5rem; 
}

.character-display{
    text-align: right;
    color: #BBB;

    &.error-text{
        color: $danger;
    }
}

.submit-contact-form{
    float: right;
}

.orderInfo-hide {
    display: none;
}

.no_orders {
    @include media-breakpoint-down(md) {
        text-align: center;
        padding-bottom: 4%;
    }
}
#navigation {
    // L1 spacing only
    .level-1 > li {
        > a {
            $base: map-get($grid-breakpoints, lg);
            $max: map-get($grid-breakpoints, xl);
            $breakpoints: (
                "a": $base,
                "b": $base + 90px,
                "c": $max - 240px,
                "d": $max
            );

            $spacings: (
                "a": 1.18vw,
                "b": 1.58vw,
                "c": 2.08vw,
                "d": 30px
            );

            @each $breakpoint, $spacing in $spacings {
                @include media-breakpoint-up($breakpoint, $breakpoints) {
                    padding-right: $spacing;
                    padding-left: $spacing;

                    &::before {
                        left: $spacing;
                        width: calc(100% - #{$spacing * 2});
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {

    .menu-toggle {
        display: none;
    }

    #navigation {
        color: $body-color;
        display: flex;
        flex: 1;
        font-size: 13px;
        font-weight: normal;
        letter-spacing: .5px;
        margin-left: 0;

        html[lang="fr"] & {
            margin-left: 0;
        }

        a {
            display: inline-block;

            &, &:hover {
                color: inherit;
            }

            &:hover {
                text-decoration: underline;
            }

        }

        .menu-category {
            display: flex;
            justify-content: center;
            margin: 0 auto;
            padding: 0;
            width: 100%;

            > li {

                &:hover > a {
                    background-color: transparent;
                }

            }

        }

        .level-1 > li {
                > a {
                    position: relative;
                    padding-top: 25px;
                    padding-bottom: 25px;

                    &, &:hover {
                        float: none;
                        letter-spacing: 1px;
                        text-decoration: none;
                        text-transform: uppercase;
                        width: 100%;
                    }

                    &::before {
                        background-color: transparent;
                        content: '';
                        display: block;
                        height: 3px;
                        margin-top: -5px;
                        position: absolute;
                            top: 100%;
                        transition: background-color .2s;
                    }

                }

                &:hover {
                    > a {
                        &::before {
                            background-color: $primary;
                        }
                    }
                }

            }

        .level-2 {
            @include make-container();

            background-color: $body-bg;
            border-top: 1px solid $border-color;
            border-bottom: 3px solid $primary;
            box-shadow: 0 2px 4px 0 rgba(black, .15);
            color: $gray-800;
            display: none;
            line-height: 2.31;
            margin-top: -1px;
            padding: 0;
            position: absolute;
                left: 50%;
            top: 100%;
            width: 100vw;
            transform: translateX(-50%);
            z-index: 1;

            .has-scrollbar & {
                width: calc(100vw - #{$scroll-bar});
            }

            @supports (color: var(--scroll-bar)) {
                .has-scrollbar & {
                    width: calc(100vw - var(--scroll-bar));
                }
            }

            // to pass width down to image so max-width works in IE11
            a, div {
                width: 100%;
            }

            a {
                line-height: 1.4;
            }

            img {
                max-width: 100%;
            }

            li,
            .level-3 {
                > ul {
                   padding: 0;
               }
            }

            &.is-horizontal {
                > ul {
                    @include make-row();
                    margin-top: 30px;
                    margin-bottom: 30px;

                    > li:not(.level-2__banner) {
                        @include make-col-ready();
                        @include make-col(2);
                    }

                }
            }

            &.is-vertical {
                .level-2__row {
                    @include make-row();
                    margin-top: 30px;
                    margin-bottom: 30px;

                    > ul:not(.level-2__banner) {
                        @include make-col-ready();
                        @include make-col(2);
                    }

                }


            }

            &__banner {
                display: flex;
                font-size: 14px;
                font-weight: 600;
                justify-content: flex-end;

                img {
                    @include size(auto);
                    flex: inherit;
                }

                &--span {
                    @for $i from 1 through 5 {
                        &-#{$i * 2} {
                            $col: $i * 2;
                            @if $col == 2 {
                                padding-right: ($grid-gutter-width / 2);
                                padding-left: ($grid-gutter-width / 2);
                            }
                            @include make-col($col);

                            > .col-2 {
                                @include make-col(2, $i * 2);
                            }

                        }
                    }
                }

            }

            &__alt-menu {
                font-weight: 600;
            }

        }

        .level-2,
        .level-2__row {
            > ul {
                > li {
                    > a {
                        // L2
                        &, &:hover {
                            font-size: 14px;
                            font-weight: 600;
                            text-transform: none;
                        }

                        + ul {
                            margin-top: 5px;
                        }

                    }
                }
            }
        }

        .level-1 li:hover {
            .level-2 {
                display: flex;
                justify-content: center;
            }
        }

        .level-3 {
            display: block;
            padding: 0;

            a {
                font-weight: normal;
                text-transform: none;
            }
        }

        .menu-utility-user-mobile {
            display: none;
        }

        .level-2 {

            &.is-vertical > .level-2__row,
            > ul {
                max-width: map-get($grid-breakpoints, xl) - map-get($grid-gutter-widths, lg);
                padding-right: 0;
                padding-left: 0;
                width: calc(100% + #{map-get($grid-gutter-widths, lg) / 2});
            }

        }

    }

}


/* Slider */
// BEGIN slick base styles
// DO NOT edit
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &::before,
    &::after {
        content: "";
        display: table;
    }

    &::after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: auto;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}
// END slick base styles

// BEGIN slick override styles
.slick-dotted {
    @include media-breakpoint-up(lg) {
        margin-bottom: 60px;
    }
}

.slick-arrow {
    @include size(19px, 52px);
    // @include background-svg($svg-slick-arrow);
    background-color: transparent;
    background-repeat: no-repeat;
    border: 0;
    position: absolute;
    top: 30%;
    z-index: 1;
    overflow: hidden;
    padding: 0;
    // text-indent: -999em;

    &.slick-disabled {
        cursor: default;
        opacity: .25;
    }

}

.slick-prev {
    left: -11px;
}
.slick-next {
    right: -11px;
    transform: rotate(180deg);
}

.slick-dots {
    padding: 0;
    text-align: center;
    position: absolute;
        right: 0;
        left: 0;

    li {
        background-color: #d5d5d5;
        border-radius: 50%;
        display: inline-block;
        font-size: 0;
        height: 8px;
        margin-left: 5px;
        margin-right: 5px;
        width: 8px;

        button {
            background-color: transparent;
            border: none;
            outline: none;
        }
    }
    .slick-active {
        background-color: $warm-grey;
    }
}

// prevent flash of content before slick initialized
[data-slick]:not(.slick-initialized) {
    > :first-child ~ * {
        display: none;
    }
}

.slick-prev:before,
.slick-next:before
{
    // font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: #333333;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev, .slick-next {
    &:focus, &:hover {
        color: transparent;
        outline: none;
        background: transparent;
    }
    &.sl-icon {
        color: #000;
        opacity: .5;
        &.slick-disabled {
            color: #a6a6a6;
        }
    }
}

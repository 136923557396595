$ns: "c-product-tile-section";

.#{$ns} {
    width: 100%;
    &__header {

    }

    &__tiles {
        display: flex;
        justify-content: space-between;
    }

    &__tile {
        display: inline-block;
        width: 49%;
        // hide tiles after the second one on mobile
        @include media-breakpoint-down(sm) {
            &:nth-child(n+3) {
                display: none;
            }
        }
        @include media-breakpoint-up(md) {
            width: 24%;
        }
    }



    .product-tile {

        .product-brand,
        .product-name {
            display: inline-block;
            font-size: inherit;
            margin-bottom: 0;
            text-transform: none;

            @include media-breakpoint-down(sm) {
                line-height: 1.35;
            }
        }

        .product-price {
            font-size: 12px;
            line-height: normal;
            margin-bottom: 15px;
            .price-standard {
                color: $gray-500;
                font-weight: 100;
                text-decoration: line-through;
                display: inline-block;
                margin-right: 2px;
            }
            .price-tiered {
                color: $red;
                font-size: .6em;
                line-height: 1.5em;
            }
            .price-sales.has-standard-price.final-flag {
                display: none;
            }
        }
    }

}

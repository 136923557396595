$ns: recently-viewed;

.#{$ns} {
    display: none;
    left: 0;
    position: fixed;
    top: calc(100% - 38px);
    width: 100%;
    z-index: $zindex-recently-viewed - 1;

    @include media-breakpoint-up(lg) {
        display: block;
    }

    &.is-active {
        bottom: 0;
        top: auto;
        z-index: $zindex-recently-viewed;
    }

    &__tab__inner,
    &__items {
        background-color: $light-grey;
    }
    &__tab {
        display: inline-block;
        text-align: right;
        width: 100%;

        &__inner {
            @include background-svg($svg-recently-viewed-icon);

            background-position: 12px center;
            background-repeat: no-repeat;
            background-size: 25px auto;
            cursor: pointer;
            display: inline-block;
            margin-right: map-get($grid-gutter-widths, xs);
            padding: 8px 12px 8px 49px;
            vertical-align: top;
        }
    }
    &__items {
        align-items: center;
        display: flex;
        margin-bottom: 0;
        margin-top: -1px;
        padding: map-get($grid-gutter-widths, xs);
    }
    &__item {
        flex: 0 0 72px;
        list-style: none;
        margin: 0 5px;
        max-width: 72px;
        text-align: center;
    }
}

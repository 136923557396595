$ns: "c-reward-img";

.#{$ns} {
    border-radius: 50%;
    display: inline-block;
    height: auto;
    margin: -1px -5px;
    overflow: hidden;
    width: 75px;

    img {
        display: block;
        width: 100%;
        height: auto;
        margin: -7.1%;
        max-width: none;
        width: 116%;
    }

    &--sm {
        margin: -1px;
        width: 50px;
    }

    &--lg {
        margin: -6px -9px;
        width: 100px;
    }

}

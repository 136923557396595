.sizinginformation {
    border: 0 none;

    th {
        background-color: inherit;
    }
    td, th {
        border-bottom: 1px solid $gray-100;
    }
    td {
        border-left: 1px solid $gray-100;
    }
    .ui-tabs-nav {
        height: 33px;
    }
    .sizing-charts {
        border: 0 none;
    }
    .tips-list {
        padding: 0 !important;
        li {
            list-style: none outside none !important;
        }
    }
}

@include media-breakpoint-up(lg) {
    .menu-utility-user {
        color: $body-color;
        display: flex;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: .5px;
        text-transform: uppercase;

        &__item {
            margin-left: 36px;
        }

        a {
            color: inherit;
        }

        .locale-select {
            .is-current {
                font-weight: 500;
                font-size: 12px;

                &.locale-select-link {
                    display: none;
                }
            }
        }

        .user-account {
            align-items: center;
            display: inline-flex;

            &__icon {
                @include size(1rem);
                margin-top: -2px;
                margin-left: 6.5px;
            }

        }

        .user-info {
            position: relative;

            .user-panel {
                background-color: $body-bg;
                box-shadow: 0 20px 30px -5px rgba(116, 116, 116, 0.4);
                display: none;
                font-size: 12px;
                font-weight: normal;
                line-height: 2.5;
                letter-spacing: .5px;
                margin-left: -1rem;
                padding: .8rem 0;
                position: absolute;
                top: 100%;
                z-index: $zindex-header-dropdown;
                text-align: left;
                text-transform: none;
                width: 162px;

                &__link {
                    display: block;
                    padding: 0 1rem;
                }
            }

            &.active,
            &:hover {
                .user-panel {
                    display: block;
                }
            }
        }


    }
}
.stores {
    display: none;

    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        margin-top: -3px;

        .menu-utility-user {
            &__item {
                margin-left: 17px;
            }
        }
    }
}

.dropdown-drawer {
    background-color: $body-bg;
    box-shadow: 0 20px 30px -5px rgba(116, 116, 116, 0.4);
    display: none;
    font-size: 12px;
    font-weight: normal;
    line-height: 2.5;
    letter-spacing: .5px;
    margin-top: 7px;
    margin-left: -1rem;
    padding: .8rem 0;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: $zindex-sticky-elem;
    text-align: left;
    text-transform: none;
    width: 162px;

    .active & {
        display: block;

    }
}

.top-banner {
    z-index: 1001 !important;
    transition: top 0.2s ease-in-out;
}

@include media-breakpoint-up(lg) {
    .has-sticky-header .top-banner {
        padding-top: 15px;
    }
    .nav-up {
            top: -141px !important;
        }
}

@include media-breakpoint-down(md) {
    .nav-down {
        /* top: 0 !important; */
        padding-top: 0;
    }
    .nav-up {
        top: -85px !important;
    }
}

@include media-breakpoint-down(sm) {
    .nav-up {
        top: -75px !important;
    }
}

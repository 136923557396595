$mini-cart-padding: 23px 24px 23px 24px;
$mini-cart-padding--top:    nth($mini-cart-padding, 1);
$mini-cart-padding--right:  nth($mini-cart-padding, 2);
$mini-cart-padding--bottom: nth($mini-cart-padding, 3);
$mini-cart-padding--left:   nth($mini-cart-padding, 4);

#mini-cart {
    position: relative;
    z-index: $zindex-minicart;


    @include media-breakpoint-up(lg) {
        margin-left: 17px;
    }
    button.dw-apple-pay-button {
        max-width: 100%;
        width: 100%;
        border-radius: 0;
        padding: 13px 15px;
        margin-bottom: 20px;
        font-size: .75rem;
        font-weight: $font-weight-bold;
        height: 48px;
        background-size: 52px;
        &:hover {
            padding: 13px 15px;
            border-radius: 0;
            margin-bottom: 20px;
        }
    }
    .checkout-only {
        display: none;
    }
}

.mini-cart-total {
    border-bottom: 3px solid transparent;
    position: relative;
    transition: border-color .2s;

    .is-open & {
        border-color: transparent;
    }

    .mini-cart-link {
        align-items: center;
        color: $body-color;
        display: flex;
        font-size: 13px;
        font-weight: normal;
        letter-spacing: .5px;

        @include media-breakpoint-up(lg) {
            margin-right: -3px;
            padding: 10px 0 10px !important;
        }

        &__icon {
            @include size(32px);
            margin: 0 5px;
        }

        .minicart-quantity {
            @include size(18px);
            align-items: center;
            background-color: $primary;
            border-radius: 50%;
            color: $body-bg;
            display: flex;
            font-size: 10px;
            justify-content: center;
            margin-top: -1rem;
            position: absolute;
            top: 50%;
            right: 25px;

            @include media-breakpoint-down(md) {
                right: -3px;
            }
        }

    }

}

html[lang="fr"] {
    .mini-cart-link__label {
        @include media-breakpoint-down(lg) {
            @include sr-only();
        }
    }
}

.mini-cart-content {
    background-color: $body-bg;
    box-shadow: 0 20px 30px -5px rgba(116, 116, 116, 0.4);
    display: none;
    font-size: 12px;
    line-height: normal;
    margin-right: -15px;
    max-width: 100vw;
    position: absolute;
    top: 100%;
    right: 0;
    width: 285px;

    #mini-cart:hover & {
        display: block;
    }

}

.mini-cart-header {
    padding: 1em 1em 0.5em;
    text-transform: uppercase;
}

.mini-cart-products {
    max-height: 235px;
    overflow: auto;

    a {
        &, &:hover {
            color: inherit;
        }
    }

}

.mini-cart-product {
    display: flex;
    margin: 22px 0 40px;
    padding: 0 $mini-cart-padding--right;

    &:last-of-type {
        margin-bottom: 0;
    }

    &__image {
        flex: 0 0 75px;
        min-width: 75px;
    }

    &__details {
        flex: 1;
        padding-left: 10px;
    }

    &__name {
        font-size: 13px;
        font-weight: 600;
    }

}

.mini-cart-totals {
    padding-right: $mini-cart-padding--right;
    padding-left: $mini-cart-padding--left;
}

.mini-cart-subtotals,
.mini-cart-slot,
.mini-cart-link-cart {
    margin-top: $mini-cart-padding--top;
    margin-bottom: $mini-cart-padding--bottom;
}

.mini-cart-subtotals {
    font-weight: 600;
    text-align: right;
}

.mini-cart-slot {
    text-align: center;
}

.mini-cart-link-cart {
    display: block;
    width: 100%;
    text-align: center;
}

.checkout-mini-cart {
    .mini-cart-items-shipment {
        padding: 0px 0px 12px;

        &__title {
            display: flex;
            margin: 12px 0px 0px;
            padding: 0 $mini-cart-padding--right;
            font-weight: 600;
            font-size: 16px;
        }
    }
    .checkout-hidden {
        display: none;
    }
    .checkout-only {
        display: initial;
    }
}


.ui-dialog.dialog-add-to-cart {
    height: auto !important;
    max-height: 90vh;
    overflow: auto;
    .ui-dialog-titlebar {
        height: auto;
        padding-bottom: 2px;
        &::after {
            content: '';
            display: block;
            height: 1.2px;
            margin-top: 10px;
            width: 100%;
            background-color: $border-color;
        }
        .ui-dialog-title {
            text-transform: none;
            font-size: 22px;
            line-height: 1;
        }
        .ui-dialog-titlebar-close {
            right: 16px;
            top: 13px;
        }
    }
    .atc-modal-content {
        .main-content {
            .added-product {
                display: flex;
                margin-bottom: 20px;
                .product-image {
                    margin-right: 15px;
                    max-width: 33%;
                }
                .product-details {
                    flex: 1;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 600;
                    margin-right: 15px;
                    .product-brand {
                        font-weight: 800;
                    }
                }
            }
            .cart-info {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-bottom: 20px;
                font-size: 14px;
                font-weight: 700;
                width:100%;
                max-width: 100%;
                .cart-qty, .cart-subtotal {
                    display: inline-block;
                    margin-bottom: 0.3rem;
                }
                .cart-subtotal {
                    text-align: right;
                }
                .cart-buttons {
                    width: 100%;
                    .btn-checkout-cart, .btn-continue-shopping {
                        display: inline-block;
                        width: 100%;
                        padding: 0.7rem;
                        margin: 0.3rem 0;
                        letter-spacing: 0.08rem;
                        text-align: center;
                        text-transform: uppercase;
                    }
                    .btn-checkout-cart {
                        background-color: $primary;
                        color: $white;
                        border: 1px solid $primary;
                        &:hover {
                            background-color: darken($primary, 10%);
                            text-decoration: none;
                        }
                    }
                    .btn-continue-shopping {
                        text-decoration:  none;
                        text-underline-offset: 0.5rem;
                        border: 1px solid #000;
                        &:hover {
                            cursor: pointer;
                            border: 1px solid $black;
                            text-decoration: underline;
                            text-underline-offset: 0.5rem;
                        }
                    }
                }
            }
        }
        .atc-recommendations {
            .c-product-tile-carousel, .c-product-tile-section {
                width: 100%;
                text-align: center;
                margin: 0;

                &::before {
                    content: '';
                    display: block;
                    height: 1px;
                    margin-top: 0.5rem;
                    width: 100%;
                    background-color: $border-color;
                    position: relative;
                    top: 16px;
                }
                &__header {
                    margin: 0 auto;
                    display: inline-block;
                    padding: 0 1.3rem;
                    background-color: $white;
                    font-weight: 700;
                    font-size: 21px;
                    position: relative;
                    z-index: 1;
                }
                .slick-track, .c-product-tile-section__tiles {
                    padding-top: 15px;
                }
                .product-tile {
                    padding: 0 8px;
                    margin-bottom: 0;
                    font-size: 14px;
                    font-weight: 600;
                    .product-name {
                        font-size: 14px;
                        font-weight: 600;
                    }
                    .product-swatches {
                        .swatch {
                            margin: 0;
                            .swatch-image {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
            }
        }

        @include media-breakpoint-up(md) {
            .main-content {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                padding: 0 20px;
                .added-product {
                    width: 62%;
                }
                .cart-info {
                    width: 38%;
                }
            }
            .atc-recommendations {
                .c-product-tile-carousel {
                    .slick-slider {
                        // adjustment to allow more space for arrow buttons
                        margin-left: 15px;
                        margin-right: 15px;
                        .sl-icon.sl-arrow-icon {
                            font-size: 21px;
                        }
                    }

                    .slick-dots {
                        display: none !important;
                    }
                    .slick-dotted {
                        margin-bottom: 0;
                    }
                }
            }
        }
        @include media-breakpoint-down(sm) {
            .atc-recommendations {
                .c-product-tile-carousel {
                    // adjustments to allow a partial third slide to be visible on mobile
                    width: 90%;
                    &::before {
                        width: 112%;
                    }
                    .c-product-tile-carousel__header {
                        transform: translateX(14%);
                    }
                    .slick-dots {
                        transform: translateX(6%);
                    }
                    .slick-list, .slick-track {
                        overflow: visible;
                    }

                    .product-tile {
                        padding: 0 6px;
                    }
                }
            }
        }
    }
}

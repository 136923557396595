.breadcrumb {
    margin: 20px 0 16px;

    li {
        display: inline;
        list-style: none outside none;
    }

}

.paging-current-page {
    left: -999em;
    position: absolute;
}
.breadcrumb-element,
.breadcrumb-divider {
    color: inherit;
    font-size: 12px;
    letter-spacing: .25px;
}
.breadcrumb-element {
    &:hover {
        &::before {
            text-decoration: none;
        }
    }
    &:last-of-type {
        &::after {
            content: '';
            margin-right: 4px;
        }
    }
}

.breadcrumb-refinement {
    background-color: $white-three;
    border-radius: 10px;
    display: inline-block;
    font-size: 10px;
    letter-spacing: .58px;
    margin: 0 8px .42em 12px;
    padding: 4px 8px 3px;
    text-transform: uppercase;
    
    & + &,
    &:first-of-type {
        margin-left: 0;
    }

    &:last-of-type {
        &::after {
            content: '';
        }
    }
}

.breadcrumb-relax {
    @include background-svg($svg-modal-close);

    background-position: center center;
    background-size: 15px auto;
    display: inline-block;
    height: 14px;
    margin-left: 4px;
    vertical-align: top;
    width: 14px;
}
.breadcrumb-result-text {
    font-size: 12px;
    padding-right: 5px;
}

.breadcrumb-result-text a,
.breadcrumb .searchphraselink {
    background: none;
    color: $body-color;
    padding: 0;
}

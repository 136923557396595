/* ============================================================================
   Accordion
   ============================================================================ */

$ns: c-accordion;

//Here as placeholder to make sure var is being computed
:root {
    --elem-height: 1500px; //Default
}

.#{$ns} {
    clear: both;
    width: 100%;

    &__item {
        @include media-breakpoint-up(lg) {
            border-bottom: solid 1px $light-grey;

            &:first-of-type {
                border-top: solid 1px $light-grey;
            }

            &--no-border-top {
                &, &:first-of-type, &:last-of-type {
                    border-top: 0;
                }
            }
        }
    }

    &__header {
        cursor: pointer;
        @include background-svg($svg-accordion-expand);

        background-position: right center;
        background-repeat: no-repeat;
        display: flex;
        justify-content: space-between;
        font-family: $font-family-primary;
        font-size: 12px;
        font-weight: inherit;
        letter-spacing: 1.5px;
        line-height: 1;
        padding: 21px 1px 21px 3px;
        padding-left: 2px;
        text-transform: uppercase;
        width: 100%;

        @include media-breakpoint-up(lg) {
            font-size: inherit;
            font-weight: 600;
        }

        &-icon {
            color: #ccc;
            display: block;
            height: 16px;
            width: 16px;

            &--expand {

                .is-expanded & {
                    display: none;
                }

            }

            &--collapse {
                display: none;

                .is-expanded & {
                    display: block;
                }

            }
        }

    }

    &__body {
        overflow: hidden;
        max-height: 0;
        padding: 0 2px;
        transition: margin-bottom .15s linear,
                    margin-top 0s .15s linear,
                    max-height .15s linear,
                    padding-top 0s .15s linear,
                    padding-bottom .15s linear;
    }

    &.#{$ns}--desktop-disable {
        > .#{$ns}__item {

            @include media-breakpoint-up(lg) {
                &:first-of-type,
                &:last-of-type {
                    border: none;
                }
            }

            > .#{$ns}__header {
                padding-left: 0;

                @include media-breakpoint-up(lg) {
                    background: transparent;
                    text-transform: none;
                    padding: 0;
                }
            }

            > .#{$ns}__body {
                @include media-breakpoint-up(lg) {
                    max-height: none;
                }
            }
        }
        > .#{$ns}__item {
            &.is-expanded {
                > .#{$ns}__body {
                    @include media-breakpoint-up(lg) {
                        padding-bottom: 0;
                        padding-top: 0;
                    }
                }
            }
        }
    }
}
.#{$ns}__item {

    &.is-expanded {

        > .#{$ns}__body {
            max-height: 5000px;
            padding-bottom: 40px;
            padding-top: 19px;
            transition: margin-bottom .15s linear,
                        max-height .15s linear,
                        padding-bottom .15s linear;

            &--med {
                max-height: 2000px;
            }
            &--tall {
                max-height: 5000px;
            }
            &--xtall {
                max-height: 9999px;
            }
            &--js-height {
                max-height: var(--elem-height);
            }
        }
        > .#{$ns}__header {
            @include background-svg($svg-accordion-contract);
        }
    }
    /**
    Fallabck for IE 11
    Allows us to set the expanded max-height on the element without it overriding the contracted styles
    */
    &:not(.is-expanded) {
        > .#{$ns}__body {
            &--js-height {
                max-height: 0 !important;
            }
        }
    }

}
.#{$ns}__done {
    font-size: 12px;
    font-weight: $font-weight-bold;
    letter-spacing: 1.5px;
    margin-top: map-get($grid-gutter-widths, xs);
    width: 100%;

    button {
        border-width: 2px;
        font-weight: inherit;
        padding-bottom: 17px;
        padding-top: 17px;
        text-transform: uppercase;
        width: 100%;
    }
}

.searchBrand {
    border-radius: 25px;
    background-image: url("../images/search.svg");
    background-repeat: no-repeat;
    background-position: 3%;
    border: 1px solid #cccccc;
    width: 100%;
    padding: 7px 13px;
    background-size: 28px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    padding-left: 35px;
}

input.searchBrand:focus {
    outline: none;
}​

.searchBrand:not(:placeholder-shown) {
    background-image: none;
}

/**
 * Inserts content into body pseudo element as a flag to JS for current viewport
 * size to align CSS with JS. Required by viewport-is.js
 */

 body::after {
    content: 'mobile';
    display: none;

    @include media-breakpoint-up(sm) {
        content: 'tablet';
    }

    @include media-breakpoint-up(lg) {
        content: 'desktop';
    }

}

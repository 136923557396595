/// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin make-container() {
    width: 100%;
    margin-right: auto;
    margin-left: auto;

    @each $breakpoint, $grid-gutter-width in $grid-gutter-widths {
        @include media-breakpoint-up($breakpoint) {
            padding-right: $grid-gutter-width;
            padding-left: $grid-gutter-width;
        }
    }

}

@mixin make-row() {
    display: flex;
    flex-wrap: wrap;

    @each $breakpoint, $grid-gutter-width in $grid-gutter-widths {
        @include media-breakpoint-up($breakpoint) {
            margin-right: ($grid-gutter-width / -2);
            margin-left: ($grid-gutter-width / -2);
        }
    }

  }

  @mixin make-col-ready() {
    position: relative;
    // Prevent columns from becoming too narrow when at smaller grid tiers by
    // always setting `width: 100%;`. This works because we use `flex` values
    // later on to override this initial width.
    width: 100%;
    min-height: 1px; // Prevent collapsing

    @each $breakpoint, $grid-gutter-width in $grid-gutter-widths {
        @include media-breakpoint-up($breakpoint) {
            padding-right: ($grid-gutter-width / 2);
            padding-left: ($grid-gutter-width / 2);
        }
    }

  }

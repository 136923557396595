footer.site-footer {
    background: $white-two;
    clear: both;

    @include media-breakpoint-up(lg) {
        border-bottom: 1px solid $light-grey;
        z-index: $zindex-footer; // required for sticky refinements sidebar
    }
}
*.bottom-main-above-footer {
    background: $white;
}
.footer-container {
    @include make-container();
    @include make-container-max-widths();

    border-bottom: solid 1px $light-grey;
    padding-top: 27px;

    @include media-breakpoint-up(lg) {
        border: none;
        padding-top: 51px;
        padding-bottom: 38px;
    }

    .footer-item {
        @include make-col-ready();
        display: block;
        font-size: 12px;
        width: 100%;

        @include media-breakpoint-up(lg) {
            @include make-col(2);
        }

        &:first-child {
            border-bottom: 1px solid $light-grey;
            font-size: inherit;

            @include media-breakpoint-up(lg) {
                @include make-col(6);

                border-bottom: none;
                padding-right: map-get($grid-gutter-widths, sm);
            }
        }
    }

    .c-accordion__header {
        @include media-breakpoint-up(lg) {
            font-size: 13px;
        }
    }
    .menu-footer {
        list-style: none;
        margin: 0 8px;
        padding: 0;

        @include media-breakpoint-up(lg) {
            font-size: 13px;
            margin: 11px 0;
        }
        li {
            line-height: 2.6em;
        }
    }
    a {
        color: $body-color;
        &:hover {
            text-decoration: underline;
        }
    }
    .leaderboard-button {
        background-color: $primary;
        border-color: $primary;
        box-shadow: none;
        color: $white;
        display: inline-block;
        margin-bottom: 20px;
        &:hover {
            text-decoration: none;
        }
    }
    .social-links,
    .payment-icons {
        display: flex;
        list-style: none;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;

        @include media-breakpoint-down(xs) {
            flex-wrap: wrap;
            padding-right: 0;
        }

        a,
        li > svg {
            display: inline-block;
        }
        a,
        li > svg {
            vertical-align: top;

            svg {
                height: inherit;
                width: inherit;
            }
        }
    }
    .social-links {
        margin-bottom: 16px;

        li {
            margin-bottom: 10px;
            margin-right: 10px;
        }
        a {
            height: 30px;
            width: 31px;
        }
    }
    .payment-icons {
        margin-bottom: 31px;

        li {
            margin-bottom: 7px;
            margin-right: 7px;

            > svg {
                height: 28px;
                width: 46px;
            }
        }
    }
}

.c-leaderboard {
    .leaderboard-button {
        background-color: $primary;
        border: 1px solid $primary;
        box-shadow: none;
        color: $white;
        display: block;
        margin-bottom: 20px;
        width: 100%;
        &:hover {
            text-decoration: none;
        }
    }

    &__agreement-confirmation {
        padding-bottom: 20px;
        position: relative;

        .input-checkbox {
            ~ .error {
                position: absolute;
                bottom: 0;
                left: 20px;
            }
        }
    }
}

.email-alert-signup-text {
    font-size: 14px;
}

.footer-signup {
    align-items: flex-start;
    display: flex;
    font-size: 12px;
    margin: 0 0 map-get($spacers, 5);
    width: 100%;

    @include media-breakpoint-up(sm) {
        max-width: 475px;
    }


    &__input-container {
        display: flex;
        flex-direction: column;
        padding-right: 7px;
        width: 100%;

        .error:not(.footer-signup__input) {
            color: $danger;
            font-size: inherit;
            margin: .25em 0;
        }

    }

    &__input,
    &__submit {
        font-size: inherit;
        padding-bottom: 11px;
        padding-top: 13px;
    }

    &__input {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $black;
        border-radius: 0;
        color: inherit;
        margin-right: 16px;
        order: -1;
        outline: none;
        width: 100%;
    }

    &__submit {
        font-weight: bold;
        letter-spacing: 1.5px;
        min-width: 100px;
        text-transform: uppercase;

        @include media-breakpoint-up(sm) {
            min-width: 135px;
        }

    }

}

.copyright {
    background-color: $white-two;
    color: $warm-grey;
    font-size: 13px;
    padding: 18px 10px;
    text-align: center;

    @include media-breakpoint-up(lg) {
        border-top: solid 1px $light-grey;
        padding: 10px;
    }

}

.postscript {
    background-color: $tertiary;
    color: $body-color;
    font-style: italic;
    text-align: center;
}

#return-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: rgba(212, 212, 212, 95%);
    border: 1px solid #dcdcdc;
    width: 50px;
    height: 50px;
    z-index: 9;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    display: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;

    svg {
        height: 12px;
        width: 50px;
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
        color: black;
        margin-top: 19px;
    }
}
#return-to-top i {
    color: #000;
    margin: 0;
    position: relative;
    left: 19px;
    top: 12px;
    font-size: 12px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
#return-to-top:hover {
    background: #e6e6e6;
}
#return-to-top:hover i {
    color: #000;
    top: 10px;
}
.mcsubscribe-form {
    .c-form-row.countryselect.form-row {
        display: none;
    }
}
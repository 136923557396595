.item-list {
    margin-bottom: 1em;
    tr {
        border-bottom: 1px solid $gray-200;
        &.last {
            border: 0 none;
        }
        &.headings {
            display: none;
        }
    }
    td {
        padding: 1rem 0.5em;
        vertical-align: top;
    }

}

.item-details {
    & > a {
        font-size: 0.813rem;
    }
}

.item-details .product-list-item {
    .name {
        font-size: 1.2em;
        font-style: italic;
        padding-bottom: 0.5rem;
    }
    .value {
        font-weight: 700;
    }
    .promo {
        color: $red;
    }
    .price-standard {
        color: $gray-500;
        text-decoration: line-through;
    }
}
.item-availability ul,
.item-quantity-details ul {
    list-style: none outside none;
    margin: 0;
    padding: 0;
}
.item-dashboard {
    font-size: 0.813rem;
    width: 16rem;
    label {
        font-weight: $font-weight-bold;
        width: auto;
    }
    .field-wrapper {
        width: auto;
    }
    .option-quantity-desired {
        input {
            text-indent: 0.5rem;
            width: 3rem;
        }
    }
    .value {
        margin-top: .5rem;
    }
    .form-row {
        margin: 0;
    }
    input,
    .value,
    select
    .option-toggle-public label {
        font-size: 0.813rem;
        color: $gray-800;
    }
    .input-checkbox {
        margin: 0 5px;
    }
    .option-add-to-cart {
        margin-top: .5rem;
        white-space: nowrap;
        width: 16rem; //for IE
        width: max-content;
        label {
            color: $gray-800;
            font-weight: 700;
            margin: .5rem .5rem 0 0;
            text-transform: uppercase;
            width: auto;
        }
        input {
            font-size: 0.813rem;
            margin-right: 0.5em;
            width: 3rem;

            &[type=number] {
                width: 5rem;
            }
        }

        button {
            padding: 0 7%;
            min-width: 100px;

        }

    }
    .option-quantity-purchased span.exceeded {
        color: $red;
    }
}


.option-update {
    button {
        border: none 0px;
    }
}

.option-priority {
    label, .c-form-row__form-field-container {
        display: inline-block;
    }

    label:after {
        content: ":";
    }

    .c-form-row__form-field-container {
        width: 11.5rem;
    }
}

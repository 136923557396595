$ns: "c-store-availability";
.js-store-availability {
    width: 100%;
}
.store-info-container {
    display: block;

}

.#{$ns} {
    background-color: #f1f0ef;
    margin: map-get($spacers, 4) 0 0;
    padding: 13px 0 9px;
    text-align: center;
    vertical-align: middle;
    &__active {
        display: block;
    }

    &__store,
    &__message {
        background-position: -5px, -2px;
        line-height: -1;
        display: inline-block;
        letter-spacing: normal;
    }

    &__store {
        font-size: 15px;
        font-weight: 600;
        padding-left: 22px;
    }

    &__message {
        background-position: calc(100% + 3px) -3px;
        border-left: 1px solid $border-color;
        font-size: 15px;
        font-weight: 300;
        line-height: 1.5;
        margin-left: 10px;
        padding-right: 25px;
        padding-left: 14px;
    }

}

.search-result-content {
    padding: 0 10px;

    @include media-breakpoint-up(lg) {
        padding: 0;
    }
}
.product-tile {
    color: $black;
    margin-bottom: 15px;
    position: relative;
    text-align: left;

    @include media-breakpoint-up(lg) {
        margin-bottom: 38px;
    }

    img {
        width: 100%;
    }
    .product-image {
        height: auto;
        margin-bottom: 11px;
        padding: 0;
        position: relative;

        .quickview {
            background-color: rgba($body-color, 0.8);
            color: #fff;
            left: 50%;
            bottom: 5%;
            padding: 0.5em;
            position: absolute;
            text-align: center;
            transform: translate(-50%, -50%);
            width: 94%;
            z-index: 2;
            i {
                margin-left: 0.5em;
            }
            display:none;
        }
        &:hover {
            .quickview {
                display: block;
            }
        }
        .product-tile__badge {
            border-radius: 3px;
            display: inline-block;
            font-size: 10px;
            font-weight: $font-weight-bold;
            letter-spacing: .91px;
            padding: 0 7px;
            text-transform: uppercase;
            vertical-align: top;

            @include media-breakpoint-up(lg) {
                font-size: 11px;
                letter-spacing: .99px;
                padding: 3px 11px 2px;
            }
        }
        .product-tile__badge--new {
            color: #fff;
            color: var(--badge-new-color, #fff);
            background-color: $cherry-red;
            background-color: var(--badge-new-bg, $cherry-red);
        }
        .product-tile__badge--sale {
            color: #fff;
            color: var(--badge-sale-color, #fff);
            background-color: $warm-grey-two;
            background-color: var(--badge-sale-bg, $warm-grey-two);
        }
        .product-tile__badge--clearance {
            color: #fff;
            color: var(--badge-clearance-color, #fff);
            background-color: $warm-grey-two;
            background-color: var(--badge-clearance-bg, $warm-grey-two);
        }
        .product-tile__badge--exclusive {
            color: #fff;
            color: var(--badge-exclusive-color, #fff);
            background-color: $blue;
            background-color: var(--badge-exclusive-bg, $blue);
        }
        .product-tile__badge--custom {
            color: #fff;
            background-color: $warm-grey-two;
        }
    }
    .product-tile-below-image {
        display: flex;
        flex-flow: column;
        min-height: 145px;
        @include media-breakpoint-up(md) {
            min-height: 162px;
        }
    }
    .product-tile__badges {
        left: 0;
        position: absolute;
        top: 10px;
        z-index: 3;

        @include media-breakpoint-up(lg) {
            top: 20px;
        }
    }
    .thumb-link {
        display: block;
        position: relative;
        width: 100%;

        &.thumb-link-active {
            img {

                &:first-child{
                    opacity: 0;
                    transition: opacity .3s;
                }
                &:last-child {
                    opacity: 1;
                }
            }
        }

        img {
            display: block;
            width: 100%;

            &:last-child {
                height: auto;
                left: 0;
                min-height: 100%;
                min-width: 100%;
                opacity: 0;
                position: absolute;
                top: 0;
                z-index: 1;
            }
            &:first-child {
                min-height: 0;
                min-width: 0;
                opacity: 1;
                position: relative;
                z-index: 2;
            }
        }
    }
    .name-link-wrap {
        font-size: 1.2em;
        margin-bottom: 2px;
        overflow: auto;

        @include media-breakpoint-up(lg) {
            margin-bottom: 7px;
        }
    }
    .product-price {
        font-size: 12px;
        margin: 0;

        @include media-breakpoint-up(sm) {
            margin-bottom: 0;
        }

        .product-standard-price {
            margin-right: 6px;
            text-decoration: line-through;
        }
        .product-sales-price {
            &:not(.has-standard-price) {
                color: black;
            }
            &.has-standard-price {
                font-weight: 600;
            }
        }
    }
    .active-promo-names {
        color: $cherry-red;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: .5px;
        line-height: normal;
        margin-bottom: 7px;
        padding-top: 2px;
        @include media-breakpoint-up(sm) {
            margin-top: 10px;
        }
    }

    .product-swatches-all {
        cursor: pointer;
        font-size: 0.9em;
        margin-left: 3px;

        &.is-expanded {
            .product-swatches-all__icon {
                &:first-child {
                    display: none;
                }
                &:last-child {
                    display: block;
                }
            }
        }
    }
    .product-swatches-all__icon {
        height: 100%;
        width: 100%;

        &:last-child {
            display: none;
        }
    }
    .product-swatches {
        line-height: 0;
        display: block;
        min-height: 35px;
        ul {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            list-style: none outside none;
            margin: 0;
            margin-left: -4px;
            overflow: hidden;
            padding: 0;

            &.swatch-toggle {
                background: none repeat scroll 0 0 $white;
                display: none;
                position: absolute;
            }
        }

        &:hover ul.swatch-toggle {
            display: block;
        }

        li {
            &.product-swatches-all {
                a {
                    border-color: $greyish;
                    display: inline-block;
                    height: 26px;
                    width: 26px;
                }
            }
            img {
                height: 28px;
                width: 28px;
            }
        }
        .swatch {
            margin-right: 3px;
        }
    }
    .product-promo {
        font-size: 0.8em;
        margin: 0;
        text-transform: uppercase;
    }
    .reviews-compare-wrap {
        align-items: center;
        color: $greyish;
        display: none;
        font-size: 13px;
        margin: 6px 0;

        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: space-between;
        }

    }
    .product-compare {
        flex: 0 0 40%;
        line-height: 11px;
        text-align: right;

        label {
            font-weight: 400;
            line-height: 1;
            margin-bottom: 0;
            margin-right: 3px;
            padding: 0 0 0 .5em;
            width: auto;
        }
        input {
            border: 1px solid $greyish;
            border-radius: 0;
            height: 14px;
            margin: 0;
            vertical-align: middle;
            width: 14px;
        }
    }
    .product-review {
        flex: 0 0 60%;
        min-height: 18px;

        .rating-title {
            display: inline-block;
            margin-right: 1em;
        }
        .rating {
            display: inline-block;
        }
    }

    .wide-tiles & {
        height: auto !important; // overwrite synced heights set on element
        .product-image {
            float: left;
            padding-right: 2em;
        }
        .name-link-wrap,
        .product-pricing,
        .product-promo,
        .product-swatches,
        .product-review {
            float: left;
            text-align: left;
            width: 65%;
        }
        .product-name {
            font-size: 1.5em;
            height: auto;
            margin-top: 0;
        }
        .product-pricing {
            font-size: 1.3em;
        }
        .product-swatches {
            margin-bottom: 1em;
        }
    }
}

.capture-product-id {
    left: -999em;
    position: absolute;
}

.color-swatch,
.product-swatches-all a {
    border: 2px solid transparent;
    border-radius: 100%;
    display: inline-block;

    &.selected,
    &:hover {
        border-color: $blue;
    }

    a,
    img {
        border: 2px solid transparent;
        border-radius: 100%;
        color: $greyish;
        font-size: 0;
    }
}
.color-swatch a,
.product-swatches-all a img {
    border: 2px solid transparent;
    border-radius: 100%;
    color: $greyish;
    font-size: 0;
}

$ns: "c-rewards";

.#{$ns} {
    background: $background-gray;
    margin: map-get($spacers, 4) 0;
    padding: map-get($spacers, 3) map-get($spacers, xs);

    @include media-breakpoint-up(lg) {
        margin: map-get($spacers, 5) 0;
        padding: map-get($spacers, 3) map-get($spacers, lg);
    }

    &--billing {
        @include media-breakpoint-up(lg) {
            padding: map-get($spacers, 3);
        }
    }

    &__header {
        margin-bottom: map-get($spacers, 3);
    }

    &__logo {
        margin-left: -5px;
        width: 300px;
    }

    &__body {
        &__heading {
            margin-bottom: map-get($spacers, 4);
        }
    }

    &__promos {
        margin: 0;

        @include media-breakpoint-up(sm) {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -#{map-get($spacers, 3) / 2};
        }

        &__item {
            margin: 0 0 map-get($spacers, 3);

            @include media-breakpoint-up(sm) {
                flex: 0 0 50%;
                max-width: 50%;
                padding: 0 #{map-get($spacers, 3) / 2};
            }

            @include media-breakpoint-up(xl) {
                flex: 0 0 33.33333%;
                max-width: 33.33333%;
            }

        }

        &__promo {
            align-items: center;
            background-color: white;
            border: 1px solid $border-color;
            display: flex;
            font-size: $font-size-xs;
            flex-direction: column;
            justify-content: center;
            padding: map-get($spacers, 3);
            text-align: center;

            &__image {
                max-width: 125px;
                width: calc(100% - #{map-get($spacers, 3) * 2})
            }

            &__heading {
                font-size: 1.3em;
                font-weight: 600;
                margin: .5em 0;
            }

            &__message {
                color: $gray-600;
                letter-spacing: .5px;
                margin: map-get($spacers, 2) 0;
                text-transform: uppercase;
            }

            &__apply {
                margin: map-get($spacers, 3) 0;
            }

        }
    }

    &__upcoming {
        display: flex;
        font-size: $font-size-xs;
        margin-bottom: map-get($spacers, 3);

        &__image {
            flex: 0 0 60px;
            max-width: 60px;

            &__img {
                width: 100%;
            }

        }

        &__heading {
            font-size: 1.3em;
            font-weight: 600;
        }

        &__details {
            padding-left: map-get($spacers, 3);
        }

        &__more {
            text-transform: capitalize;
        }

    }

}
